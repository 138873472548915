import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import FormTiposAreas from './components/FormTiposAreas';
import ListadoTiposAreas from './components/ListadoTiposAreas';
import {
  getAllTiposAreasAction,
  setActivoTiposAreasAction,
} from './TiposAreasAction';

const TiposAreasView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposAreasStore = Redux.useSelector((state) => state.tiposAreas);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'typesAreas';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoTiposAreasAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {tiposAreasStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoTiposAreasAction(dispatch, null);
              await getAllTiposAreasAction(
                dispatch,
                entidadesStore.activo.id,
                null
              );
            }}
            disabled={tiposAreasStore.loadingGetAll}
            white
            text={
              tiposAreasStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={12}>
        <ListadoTiposAreas setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormTiposAreas"
        title={`${tiposAreasStore.activo ? 'Editar' : 'Nuevo'} tipo de área`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {tiposAreasStore.activo && (
          <Typography variant="h6">
            {tiposAreasStore.activo.nombres} {tiposAreasStore.activo.apellidos}
          </Typography>
        )}
        <FormTiposAreas
          activo={tiposAreasStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default TiposAreasView;
