import axios from "axios";
import { baseApi, token, isTokenValid } from "../../services/Apis";

export const usersApi = `${baseApi}/users`;
export const userCodesApi = `${baseApi}/user-codes`;

export const getAllUsuariosService = async (params, isGlobal = false) => {
  try {
    const res = await axios.get(usersApi, {
      headers: await token({isGlobal}),
      params,
    });
    return res;
  } catch (e) {
    isTokenValid(e);
    return e.response;
  }
};

export const saveUsuariosService = async (data) => {
  if (data.avatar) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }
  const res = await axios.post(usersApi, data, {
    headers: await token({
      isFormData: !!data.avatar,
    }),
  });
  return res;
};


export const preCreateUsuariosService = async (data) => {
  if (data.avatar) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }
  const res = await axios.post(usersApi + '/pre-create', data, {
    headers: await token({
      isFormData: !!data.avatar,
    }),
  });
  return res;
};

export const completeRegistrationService = async (registrationToken, data) => {
  if (data.avatar) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }
  const res = await axios.post(`${baseApi}/auth/complete-registration/${registrationToken}`, data, {
    headers: await token({
      isFormData: !!data.avatar,
    }),
  });
  return res;
};

export const updateUsuariosService = async (id, data) => {
  if (data.avatar) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }

  const res = await axios.put(`${usersApi}/${id}`, data, {
    headers: await token({
      isFormData: !!data.avatar,
    }),
  });
  return res;
};

export const getOneUsuariosService = async (id) => {
  const res = await axios.get(`${usersApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteUsuariosService = async (id) => {
  const res = await axios.delete(`${usersApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const byIdentifacionUsuariosService = async (number, entityId) => {
  let url = `${usersApi}/find-one-by-identification-number/${number}`;
  let params = {};
  if (entityId) params.entityId = entityId;
  try {
    const res = await axios.get(url, {
      headers: await token({}),
      params
    });
    return res;
  } catch (e) {
    console.log(e);
    return {
      status: 404,
    };
  }
};

export const assingCodeUsuariosService = async (id, codeId, data) => {
  if (data.avatar) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }

  const res = await axios.post(
    `${usersApi}/${id}/assign-code/${codeId}`,
    data,
    {
      headers: await token({
        isFormData: !!data.avatar,
      })
    }
  );
  return res;
};

// /admin/unassign_user_code/:user_id
export const unassignUsuariosService = async (userId, codeId) => {
  let url = `${usersApi}/${userId}/unassign-code/${codeId}`;
  const res = await axios.post(
    url,
    {},
    {
      headers: await token({}),
    }
  );
  return res;
};

export const resendEmailConfirmationUsuariosService = async (userId) => {
  let url = `${usersApi}/${userId}/resend-email-verification`;
  const res = await axios.post(
    url,
    {},
    {
      headers: await token({}),
    }
  );
  return res;
};


export const resendEmailCompleteRegistrationUsuariosService = async (userId) => {
  let url = `${usersApi}/${userId}/resend-email-complete-registration`;
  const res = await axios.post(
    url,
    {},
    {
      headers: await token({}),
    }
  );
  return res;
};

export const getSearchUsersService = async (entityId, q, userType) => {
  const res = await axios.get(usersApi, {
    headers: await token({}),
    params: {
      entityId,
      q,
      userType
    },
  });
  return res;
};

export const getSearchUserCodesService = async (entityId, q) => {
  const res = await axios.get(userCodesApi, {
    headers: await token({}),
    params: {
      entityId,
      q
    },
  });
  return res;
};

export const getAllUsuariosShortService = async (params = {}) => {
  try {
    const res = await axios.get(usersApi + '/short', {
      headers: await token({}),
      params,
    });
    return res;
  } catch (e) {
    isTokenValid(e);
    return e.response;
  }
};