import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import TiposAreasView from './TiposAreasView';

const TiposAreasPage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposAreas} routes={[]}>
      <TiposAreasView />
    </ContainerTemplate>
  );
};

export default TiposAreasPage;
