import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import LoadingComponent from '../../../components/Loading';
import * as CONST from '../../../constants/FieldsConst';
import { useNotification } from '../../../helpers/notification';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { saveTiposUsuariosAction } from '../TiposUsuariosAction';

const FormTiposUsuarios = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposUsuariosStore = Redux.useSelector((state) => state.tiposUsuarios);

  const activo = tiposUsuariosStore.activo;

  const initData = {
    [CONST.NAME]: '',
    [CONST.ACTIVE]: true,
    platform: 'PANEL',
  };


  const [tipoUsuario, setTipoUsuario] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    if (activo === null) return;
    let data = {};
    Object.keys(tipoUsuario).map(
      (key) => (data[key] = tiposUsuariosStore.activo[key])
    );
    setTimeout(() => {
      setTipoUsuario(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setTipoUsuario({ ...tipoUsuario, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = tipoUsuario[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = await _validDataForm();

    if (valid) {
      setLoadingSave(true);
      let res = await saveTiposUsuariosAction(
        { dispatch, addNotification },
        entidadesStore.activo.id,
        activo ? activo.id : null,
        {
          ...tipoUsuario,
          [CONST.ENTITY_ID]: entidadesStore.activo.id,
        }
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.NAME}
            required
            label="Nombre"
            value={tipoUsuario.name}
            onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
            helperText={dataError.name ? validFielddHelper(1) : null}
            error={dataError.name}
          />
        </Grid>
        {(!activo || !['admin', 'owner', 'client'].includes(activo.userType.code)) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id={`label-${CONST.ACTIVE}`}>Estatus</InputLabel>
              <Select
                labelId={`label-${CONST.ACTIVE}`}
                size="small"
                value={tipoUsuario.active}
                label="Estatus"
                onChange={(e) =>
                  _handleSetDataField(CONST.ACTIVE, e.target.value)
                }
                id={CONST.ACTIVE}
              >
                <MenuItem value={true}>Activo</MenuItem>
                <MenuItem value={false}>Bloqueado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {!activo && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id={`label-platform`}>Plataforma</InputLabel>
              <Select
                labelId={`label-platform`}
                size="small"
                value={tipoUsuario.platform}
                label="Plataforma"
                onChange={(e) =>
                  _handleSetDataField('platform', e.target.value)
                }
                id="platform"
              >
                <MenuItem value="PANEL">Panel</MenuItem>
                {!entidadesStore.activo.isPublic && (<MenuItem value="APP">App</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormTiposUsuarios;
