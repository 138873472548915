import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import FormTiposUsuarios from './components/FormTiposUsuarios';
import ListadoTiposUsuarios from './components/ListadoTiposUsuarios';
import {
  getAllTiposUsuariosAction,
  setActivoTiposUsuariosAction,
} from './TiposUsuariosAction';

const TiposUsuariosView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposUsuariosStore = Redux.useSelector((state) => state.tiposUsuarios);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'typesUsers';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoTiposUsuariosAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {tiposUsuariosStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoTiposUsuariosAction(dispatch, null);
              await getAllTiposUsuariosAction(
                dispatch,
                entidadesStore.activo.id,
                null
              );
            }}
            disabled={tiposUsuariosStore.loadingGetAll}
            white
            text={
              tiposUsuariosStore.loadingGetAll
                ? 'Actualizando...'
                : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={12}>
        <ListadoTiposUsuarios
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Modal
        id="modalFormTiposUsuarios"
        title={`${
          tiposUsuariosStore.activo ? 'Editar' : 'Nuevo'
        } tipo de usuario`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {tiposUsuariosStore.activo && (
          <Typography variant="h6">
            {tiposUsuariosStore.activo.nombres}{' '}
            {tiposUsuariosStore.activo.apellidos}
          </Typography>
        )}
        <FormTiposUsuarios
          activo={tiposUsuariosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default TiposUsuariosView;
