import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';
import { serialize } from 'object-to-formdata';

export const clasesDocentesApi = `${baseApi}/class-teachers`;

export const getAllClasesDocentesService = async (params) => {
  if (params) params.panel = true;
  const res = await axios.get(clasesDocentesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveClasesDocentesService = async (id, data) => {
  let res;

  const isFile = !!data.newAboutImages?.length;

  if (data.id) delete data.id;
  // if (isFile) {
  //   const formData = new FormData();
  //   for (let i = 0; i < data.newAboutImages.length; i++) {
  //     formData.append(`newAboutImages`, data.newAboutImages[i])
  //   }
  //   delete data.newAboutImages;

  //   if (data.schedules && data.schedules?.length) {
  //     for (let i = 0; i < data.schedules.length; i++) {
  //       const schedule = data.schedules[i];
  //       formData.append(`schedules[${i}][day]`, schedule.day);
  //       if (schedule.schedules && schedule.schedules.length) {
  //         for (let ii = 0; ii < schedule.schedules.length; ii++) {
  //           const time = schedule.schedules[ii];
  //           formData.append(`schedules[${i}][schedules][${ii}]`, time);
  //         }
  //       }
  //     }
  //     delete data.schedules;
  //   } else {
  //     delete data.schedules;
  //   }

  //   if (data.deletedAboutImages && data.deletedAboutImages?.length) {
  //     for (let i = 0; i < data.deletedAboutImages.length; i++) {
  //       formData.append(`deletedAboutImages[]`, data.deletedAboutImages[i]);
  //     }
  //     delete data.deletedAboutImages;
  //   } else {
  //     delete data.deletedAboutImages;
  //   }

  //   Object.keys(data).forEach((key) => {
  //     formData.append(key, data[key]);
  //   });
  //   data = formData;
  // } else {
  //   delete data.newAboutImages;
  // }

  if (!!isFile) {
    data = serialize(data, {
      nullsAsUndefineds: true,
      allowEmptyArrays: true,
      indices: true,
      noFilesWithArrayNotation: true,
    })
  }


  if (id) {
    res = await axios.put(`${clasesDocentesApi}/${id}`, data, {
      headers: await token({
        isFormData: isFile,
      }),
    });
  } else {
    res = await axios.post(clasesDocentesApi, data, {
      headers: await token({
        isFormData: isFile,
      }),
    });
  }
  return res.data;
};

export const getOneClasesDocentesService = async (id) => {
  const res = await axios.get(`${clasesDocentesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteClasesDocentesService = async (id) => {
  const res = await axios.delete(`${clasesDocentesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
