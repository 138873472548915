import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../../components';
import ActionsList from '../../../../components/ActionsList';
import LoadingComponent from '../../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../../constants/ColorsConst';
import { SU } from '../../../../constants/ConfigConst';
import * as CONST from '../../../../constants/FieldsConst';
import { downloadExcelFile } from '../../../../helpers/exportHelper';
import { useNotification } from '../../../../helpers/notification';
import * as ROUTES from '../../../../router/Routes';
import { PARAMS_RESTAURANTES } from '../../../../store/Types';
import { textLabelsTable } from '../../../invitados/components/ListadoInvitados';
import { isManagerUserCode } from '../../../modulos/modules.helpers';
import { NameComponent } from '../../../usuarios/components/ListadoUsuarios';
import {
  deleteRestaurantesAction,
  getAllRestaurantesAction,
  saveRestaurantesAction,
  setActivoRestaurantesAction,
} from '../RestaurantesAction';
import { restaurantes } from '../RestaurantesService';
import ExpandeRowRestaurantes from './ExpandedRowRestaurantes';

const ListadoRestaurantes = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const restaurantesStore = Redux.useSelector((state) => state.restaurantes);
  const loginStore = Redux.useSelector((state) => state.login);

  const isRestaurant = entidadesStore.activo.entityType.name === 'restaurant';

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const payload = {
    ...restaurantesStore.params,
    entityId: entidadesStore.activo.id,
  };

  React.useEffect(async () => {
    dispatch({ type: PARAMS_RESTAURANTES, payload });
    if (restaurantesStore.all && restaurantesStore.all.length === 0) {
      await getAllRestaurantesAction(dispatch, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const _handleInfoClick = (id) => {
    const area = restaurantesStore.all.find((el) => el.id === id);
    setActivoRestaurantesAction(dispatch, area);
    setOpenModalForm(true);
    // dispatch({ type:  payload: { data: [] } });
    // history.push(ROUTES.panelRestaurante.to);
  };

  const _handleEditClick = async (id) => {
    const area = restaurantesStore.all.find((el) => el.id === id);
    await setActivoRestaurantesAction(dispatch, area);
    setOpenModalForm(true);
  };

  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);
  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteRestaurantesAction(
      { dispatch, addNotification },
      id,
      restaurantesStore.params
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveRestaurantesAction(
      { dispatch, addNotification },
      id,
      {
        active: !restaurantesStore.activo.active,
      },
      restaurantesStore.params
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  React.useEffect(() => {
    setRows([]);
    restaurantesStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          type: el.type ? el.type.name : 'Sin tipo',
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        },
      ]);
    });
  }, [restaurantesStore.all]);

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.IMAGE_URL,
      label: 'Imagen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar alt={value} sx={{ width: 30, height: 30 }} src={value} />
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleInfoClick(tableMeta.rowData[0])}
            >
              {' '}
              {value}{' '}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.TYPE,
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip title="Información">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setActivoRestaurantesAction(dispatch, value);
                      history.push(ROUTES.panelRestaurante.to);
                    }}
                  >
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                {!isManager && (
                  <ActionsList
                    id={tableMeta.rowData[0]}
                    onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                    onDelete={!isRestaurant ? async () => {
                      await setActivoRestaurantesAction(dispatch, value);
                      setIsDelete(true);
                    } : undefined}
                    onToggleActive={async () => {
                      await setActivoRestaurantesAction(dispatch, value);
                      setIsToggleActive(true);
                    }}
                    active={value.active}
                  />
                )}
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowRestaurantes
          data={restaurantesStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },

    onDownload: () => {
      const url = `${restaurantes}/export`;
      const name = 'restaurantes';
      const params = payload;
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={restaurantesStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {restaurantesStore.activo ? (
        <Modal
          id="modalEliminarArea"
          title={`Eliminar ${restaurantesStore.activo.name}`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta área?
              <p>
                <strong>{restaurantesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(restaurantesStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {restaurantesStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${restaurantesStore.activo.active ? 'Bloquear' : 'Activar'} ${
            restaurantesStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                restaurantesStore.activo.active ? 'Bloquear' : 'Activar'
              } esta área?`}
              <p>
                <strong>{restaurantesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(restaurantesStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoRestaurantes;
