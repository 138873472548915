import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Modal } from '../../components';
import ListadoUsuarios from './components/ListadoUsuarios';
import FormUsuarios from './components/FormUsuarios';
import {
  getAllUsuariosAction,
  setActivoUsuariosAction,
} from './UsuariosAction';
import { BY_IDENTIFICACION_USUARIOS } from '../../store/Types';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const UsuariosModule = ({ isGlobal = false }) => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);

  

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'user';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }


  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    limit: 15,
    total: 0,
    sort: 'createdAt',
    sortDirection: 'desc',
  });

  const [params, setParams] = React.useState({
    entityId:
      entidadesStore.activo && !isGlobal ? entidadesStore.activo.id : null,
    page: pagination.page,
    limit: pagination.limit,
    sort: pagination.sort,
    sortDirection: pagination.sortDirection,
  });

  React.useEffect(() => {
    _handleGetAll().then(({ data, ...res }) => {
      setPagination({
        ...pagination,
        total: res.total,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.page,
    pagination.limit,
    entidadesStore.activo,
    pagination.q,
    pagination.sort,
    pagination.sortDirection,
  ]);

  const _handleGetAll = async () => {
    const newParams = {
      ...params,
      page: pagination.page,
      limit: pagination.limit,
      sort: pagination.sort,
      sortDirection: pagination.sortDirection,
      q: pagination.q,
    };
    setParams(newParams);
    console.log(isGlobal);
    return await getAllUsuariosAction(dispatch, newParams, isGlobal);

  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        md={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          {!isGlobal && (
            <BotonNuevoComponent
              click={async () => {
                dispatch({
                  type: BY_IDENTIFICACION_USUARIOS,
                  payload: null,
                });
                await setActivoUsuariosAction(dispatch, null);
                setOpenModalForm(true);
              }}
              azul
              mr={2}
            />
          )}
          <BotonNuevoComponent
            click={async () => {
              await setActivoUsuariosAction(dispatch, null);
              _handleGetAll();
            }}
            white
            disabled={usuariosStore.loadingGetAll}
            text={
              usuariosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
        </div>
        {/* <Stack direction="row" justifyContent="flex-end">
          <BotonNuevoComponent
            text={`FILTROS ${isFiltros ? 'ACTIVO' : ''}`}
            click={() => setIsFiltros(!isFiltros)}
            white={!isFiltros}
            blue={isFiltros}
            ml={2}
          />
        </Stack> */}
      </Grid>

      <Grid item md={12}>
        <ListadoUsuarios
          isGlobal={isGlobal}
          setOpenModalForm={(val) => setOpenModalForm(val)}
          pagination={pagination}
          setPagination={(val) => setPagination(val)}
          params={params}
        />
      </Grid>

      {/* <DrawerWidget open={isFiltros} onClose={(val) => setIsFiltros(val)}>
        <FiltrosUsuariosComponent />
      </DrawerWidget> */}

      <Modal
        id="modalFormUsuarios"
        title={`${
          usuariosStore.userByIdentificacion
            ? `Usuario encontrado: ${usuariosStore.userByIdentificacion.profile.firstName} ${usuariosStore.userByIdentificacion.profile.lastName}`
            : usuariosStore.activo
            ? 'Editar usuario'
            : 'Nuevo usuario'
        }`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
        maxWidth="lg"
      >
        {usuariosStore.activo && (
          <Typography variant="h6">
            {usuariosStore.activo.nombres} {usuariosStore.activo.apellidos}
          </Typography>
        )}
        <FormUsuarios
          setOpenModalForm={(val) => setOpenModalForm(val)}
          isGlobal={isGlobal}
          params={params}
        />
      </Modal>
    </Grid>
  );
};

export default UsuariosModule;
