import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const tiposAreasApi = `${baseApi}/invitation-types`;

export const getAllTiposAreasService = async (params) => {
  const res = await axios.get(tiposAreasApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveTiposAreasService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${tiposAreasApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${tiposAreasApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneTiposAreasService = async (id) => {
  const res = await axios.get(`${tiposAreasApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteTiposAreasService = async (id) => {
  const res = await axios.delete(`${tiposAreasApi}/${id}`, {
    headers: await token({}),
  });
  return res.data;
};
