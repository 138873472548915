import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import { saveEntidadesAction } from '../EntidadesAction';
import BotonNuevoComponent from './BotonNuevo';

export const metodosPagoArray = [
  {
    id: 'TRANSFER',
    name: 'Transferencia',
  },
  {
    id: 'MOBILE',
    name: 'Pago móvil',
  },
  {
    id: 'ZELLE',
    name: 'ZELLE',
  },
  {
    id: 'CASH_BS',
    name: 'Efectivo en Bolívares',
  },
  {
    id: 'CASH_US',
    name: 'Efectivo en Dólares',
  },
  {
    id: 'POS',
    name: 'Punto de Venta',
  },
  {
    id: 'ONPASS_CODE',
    name: 'Cargo directo a la acción',
  },
];



const FormMetodosPago = ({ openModal = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const [loadingSave, setLoadingSave] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState(
    entidadesStore.activo.paymentMethods.map((method) =>
      metodosPagoArray.find((method2) => method2.id === method)
    )
  );

  const _handleSubmit = async () => {
    setLoadingSave(true);
    let res = await saveEntidadesAction(
      { dispatch, addNotification },
      {
        paymentMethods: paymentMethods.map((method) => method.id),
      },
      {
        id: entidadesStore.activo.id,
        isSU: true,
      },
      entidadesStore.params
    );
    if (openModal) {
      openModal(!res);
    }
    setLoadingSave(false);
  };

  return (
    <Box component="form">
      <Grid container spacing={2} sx={{ mt: loadingSave ? 2 : 0 }}>
        <LoadingComponent
          isLoading={loadingSave || loadingSave}
          text="Guardando..."
        />
        <Grid item xs={12} md={12}>
          <Typography variant="body">Métodos de pago</Typography>
          <Autocomplete
            multiple
            id="checkboxes-methods"
            options={metodosPagoArray}
            disableCloseOnSelect
            value={paymentMethods}
            onChange={(event, newValue) => {
              setPaymentMethods(newValue);
            }}
            getOptionLabel={(option) => option.name}
            style={{ minWidth: 500 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Métodos" />
            )}
          />
        </Grid>
        <Grid item md={12} align="center" sx={{ p: 2 }}>
          {entidadesStore.activo && (
            <BotonNuevoComponent
              click={_handleSubmit}
              disabled={loadingSave}
              text="Guardar"
              morado
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

FormMetodosPago.propTypes = {
  openModal: PropTypes.func,
};

export default FormMetodosPago;
