import { PERSIST, SU, TOKEN } from '../constants/ConfigConst';
import * as Redux from 'react-redux';

const setBaseUrlEnv = () => {
  return (
    process.env.REACT_APP_API_URL || 'https://dev-onpass-api.onsolution.app'
  );
};
export const baseIp = setBaseUrlEnv();
export const baseApi = `${baseIp}/api`;
export const baseUrlAdmin = `${baseIp}/admin`;

export const token = ({ isToken = true, isFormData = false, isGlobal = false }) => {
  const token = localStorage.getItem(TOKEN);
  const headers = {};
  const storeData = JSON.parse(localStorage.getItem(PERSIST));
  const entity = JSON.parse(storeData.entidades)?.activo;
  const authUser = JSON.parse(storeData.login)?.user;
  // debugger
  if (!isGlobal) {
    if (entity && authUser && !authUser.roles.some((r) => r.name === SU)) {
      const userCode = authUser.userCodes.find((userCode) => userCode.code.entity.id === entity.id);
      if (userCode) {
        headers['userCodeId'] = userCode.id;
      }
    } else if (entity && authUser && authUser.roles.some((r) => r.name === SU)) {
      headers['x-entity-id'] = entity.id;
    }
  }


  if (isToken) {
    headers['authorization'] = `Bearer ${token}`;
    headers['content-type'] = 'application/json';
  }

  if (isFormData) headers['content-type'] = 'multipart/form-data';
  headers['x-lang'] = 'es';
  return headers;
};

export const isToken = () => {
  const token = localStorage.getItem(TOKEN);
  return !!token;
};

export const isTokenValid = (res) => {
  // if (res.status === 423) {
  // {message: "Se requiere iniciar sesión para realizar esta acción."}
  if (res.status === 401) {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(PERSIST);
    setTimeout(() => {
      window.location.replace(`${baseIp}`);
    }, 1000);
  }
};
