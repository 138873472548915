import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ButtonFile, Modal } from '../../../../components';
import { acceptOnlyImages } from '../../../../components/ButtonFile';
import LoadingComponent from '../../../../components/Loading';
import { MORADO_COLOR } from '../../../../constants/ColorsConst';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat, getDateTimezone } from '../../../../helpers';
import { useNotification } from '../../../../helpers/notification';
import {
  deleteEventosAction,
  getAllAreasAction,
  saveEventosAction,
  setActivoSubAreasAction,
} from '../../../../store/Actions';
import { getAllAreasService } from '../../../areas/AreasService';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { getAllSubAreasService } from '../../../subareas/SubAreasService';
import { FechaField } from '../../../usuarios/components/FieldsUsuarios';
import SelectFieldTiposEventosComponent from '../../tipos/components/SelectFieldTiposEventos';
import FechasTablaEventos from './FechasTablaEventos';
import ModalExcelEventos from './ModalExcelEventos';
import TabParticipantesEventos from './TabParticipantesEventos';

const getRandColor = () => {
  const r = Math.floor(Math.random() * 128); // 0-127 (más oscuro)
  const g = Math.floor(Math.random() * 128);
  const b = Math.floor(Math.random() * 128);

  return (
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};

const FormEventos = ({ setOpenModalForm }) => {
  const dispatch = useDispatch();
  const { addNotification } = useNotification();
  const formikRef = React.useRef();

  const entidadesStore = useSelector((state) => state.entidades);
  const eventosStore = useSelector((state) => state.eventos);
  const areasStore = useSelector((state) => state.areas);
  const tiposEventosStore = useSelector((state) => state.tiposEventos);

  const timezone = entidadesStore.activo.country.timezone;

  const activo = eventosStore.activo;

  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);

  const [subareas, setSubareas] = React.useState([]);

  const [fileExcel, setFileExcel] = React.useState();
  const [loadingExcel, setLoadingExcel] = React.useState(false);
  const [modalExcel, setModalExcel] = React.useState(false);
  const [isLoadingActivo, setLoadingActivo] = React.useState(false);

  const [tab, setTab] = React.useState(0);
  const [subAreasIds, setSubAreasIds] = React.useState(null);

  const date = dateformat(null, {
    full: true,
  });

  const defaultStartTime = '07:00:00';
  const defaultEndTime = '23:00:00';

  const validationSchema = Yup.object().shape({
    [CONST.NAME]: Yup.string().required('El nombre es requerido'),
  });

  // REGISTRO ACTIVO
  React.useEffect(() => {
    if (activo === null) {
      formikRef.current.resetForm();
      setSubAreasIds(null);
      return;
    }

    setLoadingActivo(true);

    setTimeout(() => {
      setSubAreasIds(activo.subareaIds);
      console.log(activo.subareaIds);
    }, 1000);

    let data = {};
    Object.keys(formikRef.current.values).map(
      (key) => (data[key] = activo[key])
    );

    setTimeout(() => {
      setLoadingActivo(false);
      formikRef.current.setValues({
        ...data,
        [CONST.TYPE_ID]: activo.type?.id ?? '',
      });
    }, 1500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const handleSubmit = async (values) => {
    try {
      if (values.isCustomPlace) {
        setModalConfirm(true);

        formikRef.current.setValues(values);
      } else {
        await enviarDatosServer();
      }
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Error al guardar el evento',
      });
    } finally {
      console.log('finally');
    }
  };

  const enviarDatosServer = async () => {
    if (activo?.status === 'FINISHED') {
      addNotification({
        type: 'error',
        message: 'No puede editar un registro caducado',
      });
      return;
    }

    const values = formikRef.current.values;

    const data = {
      ...values,
      color: values.color.toLocaleUpperCase(),
      date: getDateTimezone(moment(values.date).startOf('day'), timezone),
      endDate: getDateTimezone(moment(values.endDate).startOf('day'), timezone),
      startTime: dateformat(values.startTime, {
        complete: true,
        isHHmmss: true,
      }),
      endTime: dateformat(values.endTime, {
        complete: true,
        isHHmmss: true,
      }),
      userCodeId: null,
      entityId: entidadesStore.activo.id,
    };

    setLoadingSave(true);
    const res = await saveEventosAction(
      { dispatch, addNotification },
      activo ? activo.id : null,
      data,
      eventosStore.params
    );
    if (res) {
      formikRef.current.resetForm();
      setOpenModalForm(false);
    }
    setLoadingSave(false);
  };

  const comparaHoras = (inicio, a, b) => {
    const vInicio = a;
    const vFinal = b;

    const tIni = new Date();
    const pInicio = vInicio.split(':');
    tIni.setHours(pInicio[0], pInicio[1]);

    const tFin = new Date();
    const pFin = vFinal.split(':');
    tFin.setHours(pFin[0], pFin[1]);

    if (inicio) {
      if (tIni.getTime() < tFin.getTime()) {
        return false;
      } else if (tIni.getTime() >= tFin.getTime()) {
        return true;
      }
    }

    if (tFin.getTime() > tIni.getTime()) {
      return false;
    } else if (tFin.getTime() <= tIni.getTime()) {
      return true;
    }
  };

  const getAreas = async (params = {}) => {
    await getAllAreasService({
      entityId: entidadesStore.activo.id,
    });
  };

  const getSubareas = async (params = {}) => {
    const res = await getAllSubAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const subareas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: `${el.area.name} | ${el.name}${el.price ? ' ($)' : ''}`,
      dates: el.dates,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      interval: el.interval,
      areaName: el.area.name,
      price: el.price,
    }));
    if (res) setSubareas(subareas);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (areasStore.all && areasStore.all.length === 0) {
        await getAllAreasAction(dispatch, {
          entityId: entidadesStore.activo.id,
        });
      }
      setActivoSubAreasAction(dispatch, null);
    };

    fetchData();

    getAreas().then(() => {
      getSubareas();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (tiposEventosStore.all.length > 0) {
      setTimeout(() => {
        formikRef.current.setFieldValue(
          CONST.TYPE_ID,
          tiposEventosStore.all[0].id
        );
      }, 1500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiposEventosStore.all]);

  React.useEffect(() => {
    if (!eventosStore.slotInfo || !formikRef.current) return;

    const slotStart = eventosStore.slotInfo.start;
    const slotEnd = eventosStore.slotInfo.end;

    // Obtener fechas formateadas y validar contra fecha actual
    const today = dayjs();
    const startDate = dayjs(slotStart).isBefore(today)
      ? dateformat(today, {})
      : dateformat(slotStart, {});
    const endDate = dayjs(slotStart).isBefore(today)
      ? dateformat(today, {})
      : dateformat(slotEnd, {});

    // Obtener valores actuales del form
    const currentValues = formikRef.current.values;

    // Mapear fechas con horarios
    const dates = eventosStore.slotInfo.slots.map((date) => ({
      date: new Date(date),
      startTime: currentValues.startTime || defaultStartTime,
      endTime: currentValues.endTime || defaultEndTime,
    }));

    // Actualizar múltiples campos en una sola operación
    setTimeout(() => {
      formikRef.current.setValues(
        {
          ...currentValues,
          [CONST.DATE]: startDate,
          [CONST.END_DATE]: endDate,
          dates,
        },
        false
      ); // false evita validación innecesaria
    }, 1000);
  }, [eventosStore.slotInfo]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <Box>
      {eventosStore.activo?.id && (
        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
          aria-label="basic tabs example"
        >
          <Tab label="Datos" {...a11yProps(0)} />
          <Tab label="Participantes" {...a11yProps(1)} />
        </Tabs>
      )}

      {/* TAB 1 */}
      <CustomTabPanel
        value={tab}
        index={0}
        sx={{
          position: 'relative',
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={{
            [CONST.NAME]: activo?.name ?? '',
            [CONST.PRICE]: activo?.price ?? 0,
            [CONST.PLACE]: activo?.place ?? '',
            [CONST.COLOR]: activo?.color ?? getRandColor(),
            maxParticipants: activo?.maxParticipants ?? 30,
            [CONST.DATE]: activo?.date ?? date,
            dates: [
              {
                date,
                startTime: defaultStartTime,
                endTime: defaultEndTime,
              },
            ],
            areAllTimesEqual: true,
            [CONST.END_DATE]: date,
            [CONST.START_TIME]: defaultStartTime,
            [CONST.END_TIME]: defaultEndTime,
            [CONST.DESCRIPTION]: '',
            [CONST.ESTATUS]: 'ACTIVE',
            subareaIds: [],
            isCustomPlace: false,
            confirmationUrl: '',
            typeId: '',
            image: null,
            flyer: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => {
            const isSameDay = dayjs(values.date).isSame(
              dayjs(values.endDate),
              'day'
            );

            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    <LoadingComponent
                      isLoading={loadingSave || isLoadingActivo}
                      text={activo ? 'Actualizando...' : 'Guardando...'}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ButtonFile
                      id="Imagen"
                      onFile={(file) => setFieldValue('image', file)}
                      file={values?.image}
                      text="Subir Image"
                      onDelete={() => setFieldValue('image', null)}
                      st={{ backgroundColor: 'gray' }}
                      src={values.image ? null : activo?.imageUrl}
                      accept={acceptOnlyImages}
                      sxc={{ width: '50%' }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ButtonFile
                      id="flyer"
                      onFile={(file) => setFieldValue('flyer', file)}
                      file={values?.flyer}
                      text="Subir Flyer"
                      onDelete={() => setFieldValue('flyer', null)}
                      st={{ backgroundColor: 'gray' }}
                      src={values.flyer ? null : activo?.flyerUrl}
                      accept={acceptOnlyImages}
                      sxc={{ width: '50%' }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          size="small"
                          id={CONST.NAME}
                          name={CONST.NAME}
                          label="Nombre"
                          required
                          value={values[CONST.NAME]}
                          onChange={handleChange}
                          error={
                            touched[CONST.NAME] && Boolean(errors[CONST.NAME])
                          }
                          helperText={touched[CONST.NAME] && errors[CONST.NAME]}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {tiposEventosStore.loadingGetAll ? (
                          <Skeleton variant="rectangular" height={42} />
                        ) : (
                          <SelectFieldTiposEventosComponent
                            value={values[CONST.TYPE_ID]}
                            isAll={false}
                            onChange={(id) => setFieldValue(CONST.TYPE_ID, id)}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          id={CONST.PRICE}
                          required
                          label="Costo"
                          value={values[CONST.PRICE]}
                          type="number"
                          inputProps={{ min: 0 }}
                          min={0}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          id={CONST.COLOR}
                          required
                          label="Color"
                          value={values[CONST.COLOR]}
                          type="color"
                          min={1}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          id={'maxParticipants'}
                          required
                          label="Máximo de personas"
                          value={values.maxParticipants}
                          type="number"
                          inputProps={{ min: 0 }}
                          min={0}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FechaField
                          l="Fecha inicio"
                          v={values.date}
                          on={(e) => {
                            let value = dateformat(e, { full: true });

                            setFieldValue(CONST.DATE, value);

                            let end = new Date(
                              dateformat(values.endDate, { full: true })
                            );
                            if (new Date(value) > new Date(values.endDate)) {
                              setFieldValue(CONST.END_DATE, value);
                              end = new Date(value);
                            }

                            const dates = [];
                            let startDate = new Date(value);
                            while (startDate <= end) {
                              dates.push({
                                date: new Date(startDate),
                                startTime: values.startTime,
                                endTime: values.endTime,
                              });
                              startDate.setDate(startDate.getDate() + 1);
                            }

                            setFieldValue('dates', dates);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FechaField
                          l="Fecha final"
                          v={values.endDate}
                          on={(e) => {
                            let value = dateformat(e, { full: true });

                            setFieldValue(CONST.END_DATE, value);

                            let startDate = new Date(
                              dateformat(values.date, { full: true })
                            );
                            if (new Date(value) < new Date(values.date)) {
                              setFieldValue(CONST.DATE, value);
                              startDate = new Date(value);
                            }

                            const dates = [];
                            let end = new Date(value);
                            while (startDate <= end) {
                              dates.push({
                                date: new Date(startDate),
                                startTime: values.startTime,
                                endTime: values.endTime,
                              });
                              startDate.setDate(startDate.getDate() + 1);
                            }

                            setFieldValue('dates', dates);
                          }}
                        />
                      </Grid>

                      {!isSameDay && (
                        <Grid item xs={12} md={12}>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={values.areAllTimesEqual}
                                  checked={values.areAllTimesEqual}
                                  onChange={(e) => {
                                    let dates = values.dates;
                                    if (e.target.checked) {
                                      dates = values.dates.map((date) => ({
                                        ...date,
                                        startTime: values.startTime,
                                        endTime: values.endTime,
                                      }));
                                    }
                                    setFieldValue('dates', dates);
                                    setFieldValue(
                                      'areAllTimesEqual',
                                      e.target.checked
                                    );
                                  }}
                                />
                              }
                              label="Aplicar la misma horas a todas las fechas"
                            />
                          </FormControl>
                        </Grid>
                      )}

                      {(values.areAllTimesEqual || isSameDay) && (
                        <Grid item xs={12} md={6}>
                          <FormControl sx={{ width: '100%' }}>
                            <TextField
                              size="small"
                              type="time"
                              label="Hora de inicio"
                              value={values.startTime}
                              onChange={(e) => {
                                const res = comparaHoras(
                                  true,
                                  e.target.value,
                                  values.endTime
                                );
                                if (!res) {
                                  const dates = values.dates.map((date) => ({
                                    ...date,
                                    startTime: `${e.target.value}:00`,
                                  }));
                                  setFieldValue('dates', dates);
                                  setFieldValue(
                                    'startTime',
                                    `${e.target.value}:00`
                                  );
                                }
                              }}
                              id={CONST.START_TIME}
                            />
                          </FormControl>
                        </Grid>
                      )}

                      {(values.areAllTimesEqual || isSameDay) && (
                        <Grid item xs={12} md={6}>
                          <FormControl sx={{ width: '100%' }}>
                            <TextField
                              size="small"
                              type="time"
                              label="Hora final"
                              value={values.endTime}
                              onChange={(e) => {
                                const res = comparaHoras(
                                  true,
                                  values.startTime,
                                  e.target.value
                                );
                                if (!res) {
                                  const dates = values.dates.map((date) => ({
                                    ...date,
                                    endTime: `${e.target.value}:00`,
                                  }));
                                  setFieldValue('dates', dates);
                                  setFieldValue(
                                    'endTime',
                                    `${e.target.value}:00`
                                  );
                                }
                              }}
                              id={CONST.END_TIME}
                            />
                          </FormControl>
                        </Grid>
                      )}

                      {!values.areAllTimesEqual && !isSameDay && (
                        <Grid item xs={12} md={12}>
                          <FechasTablaEventos
                            dates={values.dates}
                            comparaHoras={comparaHoras}
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="level">Evento en subáreas</InputLabel>
                          <Select
                            id="isCustomPlace"
                            value={values.isCustomPlace}
                            labelId="isCustomPlace"
                            label="Evento en subáreas"
                            onChange={(e) => {
                              setFieldValue('isCustomPlace', e.target.value);
                            }}
                          >
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {!values.isCustomPlace ? (
                        <Grid item xs={12} md={12}>
                          <TextField
                            fullWidth
                            size="small"
                            id={CONST.PLACE}
                            multiline
                            rows={3}
                            required={!values.isCustomPlace}
                            label="Lugar"
                            value={values.place}
                            onChange={(e) =>
                              setFieldValue(CONST.PLACE, e.target.value)
                            }
                            helperText={
                              touched[CONST.PLACE] && errors[CONST.PLACE]
                            }
                            error={
                              touched[CONST.PLACE] &&
                              Boolean(errors[CONST.PLACE])
                            }
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              fullWidth
                              disableClearable
                              disableCloseOnSelect={true}
                              id="combo-box-demo"
                              options={subareas.map((el) => el.id)}
                              multiple
                              value={subAreasIds ?? values.subareaIds}
                              size="small"
                              getOptionLabel={(option) =>
                                subareas.find((el) => el.id === option)?.label
                              }
                              renderInput={(params) => {
                                return (
                                  <Grid>
                                    <TextField
                                      {...params}
                                      label="Subáreas"
                                      placeholder="Buscar subáreas"
                                    />
                                  </Grid>
                                );
                              }}
                              renderOption={(props, id, { selected }) => {
                                const { key, ...optionProps } = props;
                                const option = subareas.find(
                                  (el) => el.id === id
                                );
                                return (
                                  <li key={key} {...optionProps}>
                                    <img
                                      style={{
                                        width: 50,
                                        height: 50,
                                        marginRight: 10,
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        display: 'block',
                                      }}
                                      src={option.imageUrl}
                                      alt={option.label}
                                    />
                                    {option.label}
                                  </li>
                                );
                              }}
                              key={(option) => option.id}
                              onChange={(e, value) => {
                                setFieldValue('subareaIds', value);
                              }}
                            />
                          </FormControl>
                          {/* <Typography variant="caption" color="textSecondary">
                      Si existen reservaciones o bloqueos en las subáreas seleccionadas, estas se eliminarán y se bloquearán las horas para el evento.
                    </Typography> */}
                        </Grid>
                      )}
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          size="small"
                          id={CONST.DESCRIPTION}
                          multiline
                          rows={3}
                          label="Descripción"
                          value={values.description}
                          onChange={(e) =>
                            setFieldValue(CONST.DESCRIPTION, e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* BUTTONS */}
                  <Grid item md={12} sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      {eventosStore.activo &&
                        eventosStore.activo.confirmationUrl && (
                          <Button
                            sx={{ color: MORADO_COLOR }}
                            onClick={() => {
                              window.navigator.clipboard
                                .writeText(eventosStore.activo.confirmationUrl)
                                .then(() => {
                                  addNotification({
                                    message: 'URL copiada al portapapeles',
                                    type: 'success',
                                  });
                                })
                                .catch((err) => {
                                  addNotification({
                                    message: 'Error al copiar la URL',
                                    type: 'error',
                                  });
                                });
                            }}
                          >
                            Copiar url del evento
                          </Button>
                        )}
                      {activo?.status !== 'FINISHED' && (
                        <BotonNuevoComponent
                          type="submit"
                          text="GUARDAR"
                          disabled={loadingSave}
                          morado
                        />
                      )}
                      {activo && activo?.status !== 'FINISHED' && (
                        <BotonNuevoComponent
                          click={async () => {
                            await deleteEventosAction(
                              { dispatch, addNotification },
                              eventosStore.activo.id,
                              eventosStore.params
                            ).then(() => {
                              setOpenModalForm(false);
                            });
                          }}
                          text="ELIMINAR"
                          disabled={loadingSave}
                          red
                        />
                      )}
                    </Stack>
                  </Grid>

                  {/* - END - */}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </CustomTabPanel>

      {/* TAB 2 */}
      <CustomTabPanel value={tab} index={1}>
        <TabParticipantesEventos
          participants={eventosStore.activo?.participants}
        />
      </CustomTabPanel>

      {/* MODALS */}
      <Modal
        id="modalFileExcel"
        title={`Eventos masivos`}
        open={modalExcel}
        maxWidth="sm"
        onClose={() => setModalExcel(!modalExcel)}
      >
        <ModalExcelEventos
          loadingExcel={loadingExcel}
          setLoadingExcel={setLoadingExcel}
          setFileExcel={setFileExcel}
          fileExcel={fileExcel}
          setModalExcel={setModalExcel}
        />
      </Modal>

      <Modal
        id="modalToggleActivo"
        title="Confirmar"
        open={modalConfirm}
        maxWidth="sm"
        onClose={() => {
          const values = formikRef.current.values;
          setModalConfirm(false);
          setTimeout(() => {
            formikRef.current.setValues(values);
          }, 100);
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Si existen reservaciones o bloqueos en las subáreas seleccionadas,
            estas se eliminarán y se bloquearán las horas para el evento.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              const values = formikRef.current.values;
              setModalConfirm(false);
              setTimeout(() => {
                formikRef.current.setValues(values);
              }, 100);
              await enviarDatosServer();
            }}
          >
            ACEPTAR
          </Button>
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => {
              const values = formikRef.current.values;
              setModalConfirm(false);
              setTimeout(() => {
                formikRef.current.setValues(values);
              }, 100);
            }}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    </Box>
  );
};

export default FormEventos;
