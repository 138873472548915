export const SET_DATA_AUTH = 'SET_DATA_AUTH';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_ROL = 'SET_ROL';
export const SET_PERFIL = 'SET_PERFIL';
export const SET_ENTIDAD_LAYOUT = 'SET_ENTIDAD_LAYOUT';
export const ERROR_LOGIN = 'ERROR_LOGIN';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const SET_ACTIVO_USERS = 'SET_ACTIVO_USERS';
export const GET_BY_ATRIBUTOS_USERS = 'GET_BY_ATRIBUTOS_USERS';

export const GETALL_USUARIOS = 'GETALL_USUARIOS';
export const LOADING_GETALL_USUARIOS = 'LOADING_GETALL_USUARIOS';
export const ACTIVO_USUARIOS = 'ACTIVO_USUARIOS';
export const GET_BY_ATRIBUTOS_USUARIOS = 'GET_BY_ATRIBUTOS_USUARIOS';
export const BY_IDENTIFICACION_USUARIOS = 'BY_IDENTIFICACION_USUARIOS';
export const PARAMS_USUARIOS = 'PARAMS_USUARIOS';
export const ERROR_USUARIOS = 'ERROR_USUARIOS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USER_CODES = 'SEARCH_USER_CODES';

export const GETALL_ENTIDADES = 'GETALL_ENTIDADES';
export const LOADING_GETALL_ENTIDADES = 'LOADING_GETALL_ENTIDADES';
export const ACTIVO_ENTIDADES = 'ACTIVO_ENTIDADES';
export const LOADING_UPDATE_ENTIDADES = 'LOADING_UPDATE_ENTIDADES';
export const PARAMS_ENTIDADES = 'PARAMS_ENTIDADES';

export const GETALL_ESTATUS = 'GETALL_ESTATUS';

export const GETALL_AREAS = 'GETALL_AREAS';
export const LOADING_GETALL_AREAS = 'LOADING_GETALL_AREAS';
export const ACTIVO_AREAS = 'ACTIVO_AREAS';
export const PARAMS_AREAS = 'PARAMS_AREAS';

export const GETALL_RESTAURANTES = 'GETALL_RESTAURANTES';
export const LOADING_GETALL_RESTAURANTES = 'LOADING_GETALL_RESTAURANTES';
export const ACTIVO_RESTAURANTES = 'ACTIVO_RESTAURANTES';
export const PARAMS_RESTAURANTES = 'PARAMS_RESTAURANTES';

export const GETALL_SUBAREAS = 'GETALL_SUBAREAS';
export const LOADING_GETALL_SUBAREAS = 'LOADING_GETALL_SUBAREAS';
export const ACTIVO_SUBAREAS = 'ACTIVO_SUBAREAS';
export const PARAMS_SUBAREAS = 'PARAMS_SUBAREAS';

export const GET_ALL_INVITADOS = 'GET_ALL_INVITADOS';
export const SET_ACTIVO_INVITADOS = 'SET_ACTIVO_INVITADOS';
export const LOADING_GETALL_INVITADOS = 'LOADING_GETALL_INVITADOS';
export const PARAMS_INVITADOS = 'PARAMS_INVITADOS';

export const GET_ALL_DELIVERY = 'GET_ALL_DELIVERY';
export const SET_ACTIVO_DELIVERY = 'SET_ACTIVO_DELIVERY';
export const LOADING_GETALL_DELIVERY = 'LOADING_GETALL_DELIVERY';
export const PARAMS_DELIVERY = 'PARAMS_DELIVERY';

export const GET_ALL_ACCESOS = 'GET_ALL_ACCESOS';
export const SET_ACTIVO_ACCESOS = 'SET_ACTIVO_ACCESOS';
export const LOADING_GETALL_ACCESOS = 'LOADING_GETALL_ACCESOS';
export const PARAMS_ACCESOS = 'PARAMS_ACCESOS';

export const GETALL_RESERVAS = 'GETALL_RESERVAS';
export const ACTIVO_RESERVAS = 'ACTIVO_RESERVAS';
export const LOADING_GETALL_RESERVAS = 'LOADING_GETALL_RESERVAS';
export const PARAMS_RESERVAS = 'PARAMS_RESERVAS';

export const GETALL_RESERVAS_RESTAURANTE = 'GETALL_RESERVAS_RESTAURANTE';
export const ACTIVO_RESERVAS_RESTAURANTE = 'ACTIVO_RESERVAS_RESTAURANTE';
export const LOADING_GETALL_RESERVAS_RESTAURANTE =
  'LOADING_GETALL_RESERVAS_RESTAURANTE';
export const PARAMS_RESERVAS_RESTAURANTE = 'PARAMS_RESERVAS_RESTAURANTE';

export const GETALL_RESERVAS_ESPECIALES = 'GETALL_RESERVAS_ESPECIALES';
export const ACTIVO_RESERVAS_ESPECIALES = 'ACTIVO_RESERVAS_ESPECIALES';
export const LOADING_GETALL_RESERVAS_ESPECIALES =
  'LOADING_GETALL_RESERVAS_ESPECIALES';
export const GET_DATE_SEARCH_RESERVAS_ESPECIALES =
  'GET_DATE_SEARCH_RESERVAS_ESPECIALES';
export const GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES =
  'GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES';

export const GETALL_CODIGOS = 'GETALL_CODIGOS';
export const ACTIVO_CODIGOS = 'ACTIVO_CODIGOS';
export const LOADING_GETALL_CODIGOS = 'LOADING_GETALL_CODIGOS';
export const PARAMS_CODIGOS = 'PARAMS_CODIGOS';
export const SEARCH_CODES_CODIGOS = 'SEARCH_CODES_CODIGOS';

export const GETALL_MODULOS = 'GETALL_MODULOS';
export const SET_ACTIVO_MODULOS = 'SET_ACTIVO_MODULOS';
export const LOADING_GETALL_MODULOS = 'LOADING_GETALL_MODULOS';
export const PARAMS_MODULOS = 'PARAMS_MODULOS';
export const SET_AUTH_MODULES = 'SET_AUTH_MODULES';

export const GETALL_RECIBOS = 'GETALL_RECIBOS';
export const SET_ACTIVO_RECIBOS = 'SET_ACTIVO_RECIBOS';
export const LOADING_GETALL_RECIBOS = 'LOADING_GETALL_RECIBOS';
export const PARAMS_RECIBOS = 'PARAMS_RECIBOS';

export const GETALL_EVENTOS = 'GETALL_EVENTOS';
export const SET_ACTIVO_EVENTOS = 'SET_ACTIVO_EVENTOS';
export const LOADING_GETALL_EVENTOS = 'LOADING_GETALL_EVENTOS';
export const PARAMS_EVENTOS = 'PARAMS_EVENTOS';
export const SLOTINFO_EVENTOS = 'SLOTINFO_EVENTOS';

export const GETALL_BANCOS = 'GETALL_BANCOS';
export const SET_ACTIVO_BANCOS = 'SET_ACTIVO_BANCOS';
export const LOADING_GETALL_BANCOS = 'LOADING_GETALL_BANCOS';
export const PARAMS_BANCOS = 'PARAMS_BANCOS';

export const GETALL_APIKEYS = 'GETALL_APIKEYS';
export const SET_ACTIVO_APIKEYS = 'SET_ACTIVO_APIKEYS';
export const LOADING_GETALL_APIKEYS = 'LOADING_GETALL_APIKEYS';
export const PARAMS_APIKEYS = 'PARAMS_APIKEYS';

export const GETALL_TIPOS_NIVEL_CODE = 'GETALL_TIPOS_NIVEL_CODE';
export const SET_ACTIVO_TIPOS_NIVEL_CODE = 'SET_ACTIVO_TIPOS_NIVEL_CODE';
export const ACTIVO_TIPOS_NIVEL_CODE = 'ACTIVO_TIPOS_NIVEL_CODE';
export const LOADING_GETALL_TIPOS_NIVEL_CODE =
  'LOADING_GETALL_TIPOS_NIVEL_CODE';
export const PARAMS_TIPOS_NIVEL_CODE = 'PARAMS_TIPOS_NIVEL_CODE';

export const GETALL_ENTIDAD_BANCOS = 'GETALL_ENTIDAD_BANCOS';
export const SET_ACTIVO_ENTIDAD_BANCOS = 'SET_ACTIVO_ENTIDAD_BANCOS';
export const LOADING_GETALL_ENTIDAD_BANCOS = 'LOADING_GETALL_ENTIDAD_BANCOS';
export const PARAMS_ENTIDAD_BANCOS = 'PARAMS_ENTIDAD_BANCOS';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const LOADING_DASHBOARD = 'LOADING_DASHBOARD';

export const GETALL_DOCUMENTOS = 'GETALL_DOCUMENTOS';
export const ACTIVO_DOCUMENTOS = 'ACTIVO_DOCUMENTOS';
export const LOADING_GETALL_DOCUMENTOS = 'LOADING_GETALL_DOCUMENTOS';
export const PARAMS_DOCUMENTOS = 'PARAMS_DOCUMENTOS';

export const GETALL_DESCUENTOS = 'GETALL_DESCUENTOS';
export const ACTIVO_DESCUENTOS = 'ACTIVO_DESCUENTOS';
export const LOADING_GETALL_DESCUENTOS = 'LOADING_GETALL_DESCUENTOS';
export const PARAMS_DESCUENTOS = 'PARAMS_DESCUENTOS';

export const GETALL_NOTIFICACIONES = 'GETALL_NOTIFICACIONES';
export const ACTIVO_NOTIFICACIONES = 'ACTIVO_NOTIFICACIONES';
export const LOADING_GETALL_NOTIFICACIONES = 'LOADING_GETALL_NOTIFICACIONES';
export const PARAMS_NOTIFICACIONES = 'PARAMS_NOTIFICACIONES';

export const GETALL_METODOS_PAGO = 'GETALL_METODOS_PAGO';
export const ACTIVO_METODOS_PAGO = 'ACTIVO_METODOS_PAGO';
export const LOADING_GETALL_METODOS_PAGO = 'LOADING_GETALL_METODOS_PAGO';
export const PARAMS_METODOS_PAGO = 'PARAMS_METODOS_PAGO';

export const GETALL_REFERIDOS = 'GETALL_REFERIDOS';
export const ACTIVO_REFERIDOS = 'ACTIVO_REFERIDOS';
export const LOADING_GETALL_REFERIDOS = 'LOADING_GETALL_REFERIDOS';
export const PARAMS_REFERIDOS = 'PARAMS_REFERIDOS';

export const GETALL_USERS_DELETE = 'GETALL_USERS_DELETE';
export const ACTIVO_USERS_DELETE = 'ACTIVO_USERS_DELETE';
export const LOADING_GETALL_USERS_DELETE = 'LOADING_GETALL_USERS_DELETE';
export const PARAMS_USERS_DELETE = 'PARAMS_USERS_DELETE';

export const GETALL_TIPOS_CONTACTOS = 'GETALL_TIPOS_CONTACTOS';
export const ACTIVO_TIPOS_CONTACTOS = 'ACTIVO_TIPOS_CONTACTOS';
export const LOADING_GETALL_TIPOS_CONTACTOS = 'LOADING_GETALL_TIPOS_CONTACTOS';
export const PARAMS_TIPOS_CONTACTOS = 'PARAMS_TIPOS_CONTACTOS';

export const GETALL_TIPOS_DOCUMENTOS = 'GETALL_TIPOS_DOCUMENTOS';
export const ACTIVO_TIPOS_DOCUMENTOS = 'ACTIVO_TIPOS_DOCUMENTOS';
export const LOADING_GETALL_TIPOS_DOCUMENTOS =
  'LOADING_GETALL_TIPOS_DOCUMENTOS';
export const PARAMS_TIPOS_DOCUMENTOS = 'PARAMS_TIPOS_DOCUMENTOS';

export const GETALL_TIPOS_RESTAURANTE = 'GETALL_TIPOS_RESTAURANTE';
export const ACTIVO_TIPOS_RESTAURANTE = 'ACTIVO_TIPOS_RESTAURANTE';
export const LOADING_GETALL_TIPOS_RESTAURANTE =
  'LOADING_GETALL_TIPOS_RESTAURANTE';
export const PARAMS_TIPOS_RESTAURANTE = 'PARAMS_TIPOS_RESTAURANTE';

export const GETALL_TIPOS_EVENTOS = 'GETALL_TIPOS_EVENTOS';
export const ACTIVO_TIPOS_EVENTOS = 'ACTIVO_TIPOS_EVENTOS';
export const LOADING_GETALL_TIPOS_EVENTOS = 'LOADING_GETALL_TIPOS_EVENTOS';
export const PARAMS_TIPOS_EVENTOS = 'PARAMS_TIPOS_EVENTOS';

export const GETALL_TIPOS_IMAGEN_EVENTOS = 'GETALL_TIPOS_IMAGEN_EVENTOS';
export const ACTIVO_TIPOS_IMAGEN_EVENTOS = 'ACTIVO_TIPOS_IMAGEN_EVENTOS';
export const LOADING_GETALL_TIPOS_IMAGEN_EVENTOS =
  'LOADING_GETALL_TIPOS_IMAGEN_EVENTOS';
export const PARAMS_TIPOS_IMAGEN_EVENTOS = 'PARAMS_TIPOS_IMAGEN_EVENTOS';

export const GETALL_TIPOS_INVITACION = 'GETALL_TIPOS_INVITACION';
export const ACTIVO_TIPOS_INVITACION = 'ACTIVO_TIPOS_INVITACION';
export const LOADING_GETALL_TIPOS_INVITACION =
  'LOADING_GETALL_TIPOS_INVITACION';
export const PARAMS_TIPOS_INVITACION = 'PARAMS_TIPOS_INVITACION';

export const GETALL_AREAS_CATEGORIAS = 'GETALL_AREAS_CATEGORIAS';
export const ACTIVO_AREAS_CATEGORIAS = 'ACTIVO_AREAS_CATEGORIAS';
export const ALERT_VIEW_AREAS_CATEGORIAS = 'ALERT_VIEW_AREAS_CATEGORIAS';
export const LOADING_GETALL_AREAS_CATEGORIAS =
  'LOADING_GETALL_AREAS_CATEGORIAS';

export const GETALL_PUERTAS = 'GETALL_PUERTAS';
export const ACTIVO_PUERTAS = 'ACTIVO_PUERTAS';
export const LOADING_UPDATE_PUERTAS = 'LOADING_UPDATE_PUERTAS';

export const CLEAR_ALL = 'CLEAR_ALL';

export const GET_ALL_CONTACTOS = 'GET_ALL_CONTACTOS';
export const SET_ACTIVO_CONTACTOS = 'SET_ACTIVO_CONTACTOS';
export const LOADING_GETALL_CONTACTOS = 'LOADING_GETALL_CONTACTOS';
export const PARAMS_CONTACTOS = 'PARAMS_CONTACTOS';

export const GETALL_SETTINGS = 'GETALL_SETTINGS';
export const ACTIVO_SETTINGS = 'ACTIVO_SETTINGS';
export const LOADING_GETALL_SETTINGS = 'LOADING_GETALL_SETTINGS';
export const PARAMS_SETTINGS = 'PARAMS_SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';

export const GETALL_PRECIOS_CUSTOM = 'GETALL_PRECIOS_CUSTOM';
export const ACTIVO_PRECIOS_CUSTOM = 'ACTIVO_PRECIOS_CUSTOM';
export const LOADING_GETALL_PRECIOS_CUSTOM = 'LOADING_GETALL_PRECIOS_CUSTOM';
export const PARAMS_PRECIOS_CUSTOM = 'PARAMS_PRECIOS_CUSTOM';
export const GET_DATE_SEARCH_PRECIOS_CUSTOM = 'GET_DATE_SEARCH_PRECIOS_CUSTOM';
export const GET_DATA_SEARCH_AREAS_PRECIOS_CUSTOM =
  'GET_DATA_SEARCH_AREAS_PRECIOS_CUSTOM';

export const GETALL_MOROSIDAD = 'GETALL_MOROSIDAD';
export const SET_ACTIVO_MOROSIDAD = 'SET_ACTIVO_MOROSIDAD';
export const LOADING_GETALL_MOROSIDAD = 'LOADING_GETALL_MOROSIDAD';
export const PARAMS_MOROSIDAD = 'PARAMS_MOROSIDAD';

export const GETALL_TIPOS_AREAS = 'GETALL_TIPOS_AREAS';
export const ACTIVO_TIPOS_AREAS = 'ACTIVO_TIPOS_AREAS';
export const LOADING_GETALL_TIPOS_AREAS = 'LOADING_GETALL_TIPOS_AREAS';
export const PARAMS_TIPOS_AREAS = 'PARAMS_TIPOS_AREAS';

export const GETALL_TIPOS_USUARIOS = 'GETALL_TIPOS_USUARIOS';
export const ACTIVO_TIPOS_USUARIOS = 'ACTIVO_TIPOS_USUARIOS';
export const LOADING_GETALL_TIPOS_USUARIOS = 'LOADING_GETALL_TIPOS_USUARIOS';
export const PARAMS_TIPOS_USUARIOS = 'PARAMS_TIPOS_USUARIOS';
