import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import * as ROUTES from './Routes';
import * as Redux from 'react-redux';
import Template from '../components/template';

import {
  Login,
  Recover,
  CorreoVerificado,
  TokenExpirado,
  Codigos,
  UpdatePassword,
  UserDeletionRequest,
  EventConfirmationParticipant,
  CompletarRegistro
} from '../modules';

import { ADMIN, SU, TOKEN } from '../constants/ConfigConst';
import { getAllModulosAdminService } from '../services';
import { SET_AUTH_MODULES } from '../store/Types';
import { routesMap } from './modules';

const Routers = () => {
  const dispatch = Redux.useDispatch();
  const loginStore = Redux.useSelector((state) => state.login);
  const isAdmin = loginStore.roles?.indexOf(ADMIN) !== -1;
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const entity = entidadesStore.activo;
  const user = loginStore.user;

  React.useEffect(() => {
    const fetchData = async () => {
      if (isAdmin && entity) {
        const userCode = user.userCodes.find(
          (userCode) => userCode.code.entity.id === entity.id
        );
        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        await sleep(1000);
        getAllModulosAdminService(userCode.id).then((res) => {
          dispatch({
            type: SET_AUTH_MODULES,

            payload: res.data.data,
          });
        });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  return (
    <Router>
      <Switch>
        <PublicPage path="/" exact>
          <Login />
        </PublicPage>
        <PublicPage path={ROUTES.login.to} exact>
          <Login />
        </PublicPage>
        <PublicPage path={ROUTES.recover.to} exact>
          <Recover />
        </PublicPage>
        <Route path={ROUTES.correoVerificado.to} exact>
          <CorreoVerificado />
        </Route>
        <Route path={ROUTES.tokenExpirado.to} exact>
          <TokenExpirado />
        </Route>
        <Route path={ROUTES.updatePassword.to} exact>
          <UpdatePassword />
        </Route>
        <Route path="/events/:uid/confirm-participant" exact>
          <EventConfirmationParticipant />
        </Route>
        <Route path="/completarRegistro" exact>
          <CompletarRegistro />
        </Route>

        <Route path={ROUTES.userDeletionRequest.to} exact>
          <UserDeletionRequest />
        </Route>

        <Template>
          <Switch>
            {Object.entries(routesMap).map(([route, Component]) => (
              <PrivatePage path={route} exact key={route}>
                <Component />
              </PrivatePage>
            ))}

            <PrivatePage path={ROUTES.residencias.to}>
              <Codigos route={ROUTES.residencias} />
            </PrivatePage>

            <PrivatePage path={ROUTES.acciones.to}>
              <Codigos route={ROUTES.acciones} />
            </PrivatePage>
          </Switch>
        </Template>
      </Switch>
    </Router>
  );
};

const validarToken = () => {
  const token = localStorage.getItem(TOKEN);
  let isValid = false;
  try {
    isValid = token && token !== '';
  } catch (e) {
    return false;
  }
  return isValid;
};

const PublicPage = ({ children, ...props }) => {
  const loginStore = Redux.useSelector((state) => state.login);

  const rol = loginStore.rol;
  let isAuthenticated = validarToken();
  return !isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={rol === SU ? ROUTES.panelControl.to : ROUTES.dashboard.to} />
  );
};

const PrivatePage = ({ children, ...props }) => {
  let isAuthenticated = validarToken();
  return isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.login.to} />
  );
};

export default Routers;
