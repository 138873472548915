import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import ButtonFile, { acceptOnlyImages } from '../../../components/ButtonFile';
import LoadingComponent from '../../../components/Loading';
import AreaCategoriasField from '../../../components/fields/AreaCategoriasField';
import * as CONST from '../../../constants/FieldsConst';
import { DAY, HOUR_BLOCK } from '../../../constants/enumsConst';
import { dateformat } from '../../../helpers';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { saveAreasAction } from '../AreasAction';

import { Checkbox, FormControlLabel } from '@mui/material';

import { useNotification } from '../../../helpers/notification';
import { baseApi, token } from '../../../services/Apis';
import {
  CustomTabPanel,
  a11yProps,
} from '../../subareas/components/FormSubAreas';
import FormAreaExtra from './FormExtras';
import { Modal } from '../../../components';
import { Edit, Remove } from '@mui/icons-material';
import useFetch from 'http-react';

const getCategories = async () => {
  const { data } = await axios.get(`${baseApi}/area-categories`, {
    headers: await token({ isToken: true }),
    params: {
      active: true,
    },
  });
  return data.data;
};


const FormAreas = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);

  const [fileImage, setFileImage] = React.useState();
  const [fileMapImage, setFileMapImage] = React.useState();
  const [addExtraModal, setAddExtraModal] = React.useState(false);
  
  const [area, setArea] = React.useState({
    [CONST.NAME]: '',
    [CONST.OPENING_TIME]: '07:00',
    [CONST.CLOSING_TIME]: '18:00',
    [CONST.DAYS_MAX_RESERVAS]: 1,
    [CONST.MAX_RESERVAS]: 1,
    [CONST.LIMIT_RESERVATIONS]: 1,
    [CONST.ACTIVE_RESERVES]: 1,
    [CONST.DAYS_PREV_FOR_RESERVE]: 1,
    [CONST.BLOCK_RESERVATION]: HOUR_BLOCK,
    [CONST.CONTINUOS_BLOCK]: 1,
    [CONST.ACTIVE]: true,
    [CONST.CATEGORY_ID]: 2,
    [CONST.ALLOW_CREATE_EVENTS]: false,
    [CONST.WAIT_BLOCK_TIME_LIMIT]: 5,
    [CONST.WAITLIST_SEND_INTERVAL]: 20,
    isNotifyNotAttendees: false,
    extras: [],
    accessDoorId: null,
  });
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.DAYS_MAX_RESERVAS]: false,
    [CONST.MAX_RESERVAS]: false,
    [CONST.LIMIT_RESERVATIONS]: false,
    [CONST.ACTIVE_RESERVES]: false,
    [CONST.DAYS_PREV_FOR_RESERVE]: false,
    [CONST.CONTINUOS_BLOCK]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingCategorias, setLoadingCategorias] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [tabs, setTabs] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoadingCategorias(true);
      const categories = await getCategories();
      setCategories(categories);
      setLoadingCategorias(false);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (areasStore.activo === null) return;
    let data = {};
    Object.keys(area).map((key) => (data[key] = areasStore.activo[key]));

    data = {
      ...data,
      [CONST.CATEGORY_ID]: areasStore.activo.category.id,
      [CONST.OPENING_TIME]: dateformat(areasStore.activo?.openingTime, {
        isHHmmss: true,
        complete: true,
      }),
      [CONST.CLOSING_TIME]: dateformat(areasStore.activo?.closingTime, {
        isHHmmss: true,
        complete: true,
      }),
    };

    setArea(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areasStore.activo]);

  
  const { data: accessDoorsData, loading: loadingAccessDoors, reFetch } = useFetch(
    `${baseApi}/access-doors/short`,
    {
      id: `access-doors-app-is-areas-${entidadesStore.activo.id}-${area.accessDoorId}`,
      headers: token({}),
      query: {
        isArea: true,
        excludeId: areasStore.activo?.accessDoor?.id
      },
    }

  );

  // console.log(accessDoors);
  const accessDoors = accessDoorsData || [];

  const _handleSetDataFieldArea = (key, value) =>
    setArea({ ...area, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = area[key];
      const valid = val === null || val === '';
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = await _validDataForm();
    if (valid) {
      const data = {
        ...area,
        [CONST.DAYS_PREV_FOR_RESERVE]: Number(area.daysPrevForReserve),
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
        [CONST.OPENING_TIME]: dateformat(area.openingTime, {
          complete: true,
          isHHmmss: true,
        }),
        [CONST.CLOSING_TIME]: dateformat(area.closingTime, {
          complete: true,
          isHHmmss: true,
        }),
        accessDoorId: area.accessDoorId,
      };
      if (fileImage) data[CONST.IMAGE] = fileImage;
      if (fileMapImage) data[CONST.MAP_IMAGE] = fileMapImage;
      setLoadingSave(true);
      let res = await saveAreasAction(
        { dispatch, addNotification },
        areasStore.activo === null ? null : areasStore.activo.id,
        data,
        areasStore.params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  const onTabs = (event, newValue) => {
    setTabs(newValue);
  };

  // const tabPanel1 = <TabPanel value={1}>
  const infoForm = (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <ButtonFile
          id={CONST.IMG_FOTO}
          onFile={(file) => setFileImage(file)}
          file={fileImage}
          text="Subir imagén del área"
          sxc={{ marginTop: '5px' }}
          size="60%"
          name={areasStore.activo ? areasStore.activo.name : null}
          onDelete={() => setFileImage(null)}
          accept={acceptOnlyImages}
          src={
            fileImage
              ? null
              : areasStore.activo
              ? areasStore.activo.imageUrl
              : null
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              size="small"
              id={CONST.NAME}
              required
              label="Nombre"
              value={area.name}
              onChange={(e) =>
                _handleSetDataFieldArea(CONST.NAME, e.target.value)
              }
              helperText={dataError.name ? validFielddHelper(1) : null}
              error={dataError.name}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box sx={{ mb: 1 }}>
              <LoadingComponent isLoading={loadingCategorias} text="none" />
            </Box>
            <AreaCategoriasField
              value={area[CONST.CATEGORY_ID]}
              onChange={(e, v) =>
                _handleSetDataFieldArea(CONST.CATEGORY_ID, e.target.value)
              }
              // error={dataRequired.countryId}
              // disabled={loginStore.rol === ADMIN}
              categorias={categories}
            />
          </Grid>

          {area.blockReservation === HOUR_BLOCK && (
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <Typography variant="body">Hora de apertura</Typography>
                <TextField
                  size="small"
                  type="time"
                  value={area.openingTime}
                  onChange={(e) =>
                    _handleSetDataFieldArea(CONST.OPENING_TIME, e.target.value)
                  }
                  id={CONST.OPENING_TIME}
                />
              </FormControl>
            </Grid>
          )}
          {area.blockReservation === HOUR_BLOCK && (
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <Typography variant="body">Hora de cierre</Typography>
                <TextField
                  size="small"
                  type="time"
                  value={area.closingTime}
                  onChange={(e) =>
                    _handleSetDataFieldArea(CONST.CLOSING_TIME, e.target.value)
                  }
                  id={CONST.CLOSING_TIME}
                />
              </FormControl>
            </Grid>
          )}
          {area.blockReservation === DAY && (
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={area.allowCreateEvents}
                      checked={area.allowCreateEvents}
                      onChange={(e) => {
                        const newArea = {
                          ...area,
                          allowCreateEvents: e.target.checked,
                        };
                        setArea(newArea);
                      }}
                      name={CONST.ALLOW_CREATE_EVENTS}
                    />
                  }
                  label="Permitir crear eventos"
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-tiporeserva-label">
                Tipo de reserva
              </InputLabel>
              <Select
                labelId="select-tiporeserva-label"
                size="small"
                value={area.blockReservation}
                label="Tipo de reserva"
                onChange={(e) => {
                  let entry = '07:00:00';
                  let exit = '18:00:00';

                  if (e.target.value === DAY) {
                    entry = '00:00:00';
                    exit = '23:59:59';
                  } else {
                    if (areasStore.activo) {
                      const area = areasStore.activo;
                      entry = area.openingTime;
                      exit = area.closingTime;
                    } else {
                      entry = '07:00:00';
                      exit = '18:00:00';
                    }
                  }
                  setArea({
                    ...area,
                    [CONST.OPENING_TIME]: entry,
                    [CONST.CLOSING_TIME]: exit,
                    [CONST.BLOCK_RESERVATION]: e.target.value,
                  });
                }}
                id={CONST.BLOCK_RESERVATION}
              >
                <MenuItem value={HOUR_BLOCK}>Bloques</MenuItem>
                <MenuItem value={DAY}>Todo el día</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-estatus-label">Estatus</InputLabel>
              <Select
                labelId="select-estatus-label"
                size="small"
                label="Estatus"
                value={area.active}
                onChange={(e) =>
                  _handleSetDataFieldArea(CONST.ACTIVE, e.target.value)
                }
                id={CONST.ACTIVE}
              >
                <MenuItem value={true}>Activo</MenuItem>
                <MenuItem value={false}>Bloqueado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const validacionesForm = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          {accessDoors.length > 0 && (
            <Grid item xs={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Typography variant="body">
                    Puerta de acceso
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    labelId={'accessDoorId'}
                    id={'accessDoorId'}
                    size="small"
                    value={area.accessDoorId}
                    placeholder="Selecciona una puerta de acceso"
                    fullWidth
                    onChange={(e) => {
                      _handleSetDataFieldArea('accessDoorId', e.target.value)
                    }}
                  >
                    {accessDoors.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}

                  </Select>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Número de reservas permitidas
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={area.maxReserves}
                  onChange={(e) =>
                    _handleSetDataFieldArea(CONST.MAX_RESERVAS, e.target.value)
                  }
                  id={CONST.MAX_RESERVAS}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.maxReserves ? validFielddHelper(1) : null
                  }
                  error={dataError.maxReserves}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Cantidad de días para el número de reservas permitidas
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={area.daysMaxReserves}
                  onChange={(e) =>
                    _handleSetDataFieldArea(
                      CONST.DAYS_MAX_RESERVAS,
                      e.target.value
                    )
                  }
                  id={CONST.DAYS_MAX_RESERVAS}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.daysMaxReserves ? validFielddHelper(1) : null
                  }
                  error={dataError.daysMaxReserves}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">Reservas activas</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={area.activeReserves}
                  onChange={(e) =>
                    _handleSetDataFieldArea(
                      CONST.ACTIVE_RESERVES,
                      e.target.value
                    )
                  }
                  id={CONST.ACTIVE_RESERVES}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.activeReserves ? validFielddHelper(1) : null
                  }
                  error={dataError.activeReserves}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Mínimo de días previos
                  {/* A partir de cuantos días previos se puede reservar */}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={area.daysPrevForReserve}
                  onChange={(e) =>
                    _handleSetDataFieldArea(
                      CONST.DAYS_PREV_FOR_RESERVE,
                      e.target.value
                    )
                  }
                  id={CONST.DAYS_PREV_FOR_RESERVE}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.daysPrevForReserve ? validFielddHelper(1) : null
                  }
                  error={dataError.daysPrevForReserve}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Cantidad de bloques seguidos a considerar como una reserva
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={area.continuousBlock}
                  onChange={(e) =>
                    _handleSetDataFieldArea(
                      CONST.CONTINUOS_BLOCK,
                      e.target.value
                    )
                  }
                  id={CONST.CONTINUOS_BLOCK}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.continuousBlock ? validFielddHelper(1) : null
                  }
                  error={dataError.continuousBlock}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Tiempo limite para bloques de espera (minutos)
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={area.waitBlockTimeLimit}
                  onChange={(e) =>
                    _handleSetDataFieldArea(
                      CONST.WAIT_BLOCK_TIME_LIMIT,
                      Number(e.target.value)
                    )
                  }
                  id={CONST.WAIT_BLOCK_TIME_LIMIT}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.waitBlockTimeLimit ? validFielddHelper(1) : null
                  }
                  error={dataError.waitBlockTimeLimit}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Tiempo de espera para enviar notificación en cola a miembros
                  de lista de espera (segundos)
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={area.waitlistSendInterval}
                  onChange={(e) =>
                    _handleSetDataFieldArea(
                      CONST.WAITLIST_SEND_INTERVAL,
                      Number(e.target.value)
                    )
                  }
                  id={CONST.WAITLIST_SEND_INTERVAL}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.waitlistSendInterval ? validFielddHelper(1) : null
                  }
                  error={dataError.waitlistSendInterval}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={area.isNotifyNotAttendees}
                    onChange={(e) => {
                      setArea({
                        ...area,
                        isNotifyNotAttendees: e.target.checked,
                      });
                    }}
                  />
                }
                label="Notificar a los usuarios que no asistieron"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const mapaForm = (
    <ButtonFile
      id={CONST.MAP_IMG_FOTO}
      onFile={(file) => setFileMapImage(file)}
      file={fileMapImage}
      text="Subir imagén del mapa área"
      sxc={{ marginTop: '5px' }}
      size="50%"
      name={areasStore.activo ? areasStore.activo.name : null}
      onDelete={() => setFileMapImage(null)}
      accept={acceptOnlyImages}
      src={
        fileMapImage
          ? null
          : areasStore.activo
          ? areasStore.activo.mapImageUrl
          : null
      }
    />
  );

  const extraForm = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Button onClick={() => setAddExtraModal(true)} variant="contained">
          <Typography variant="body">Agregar extra</Typography>
        </Button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="right">Descripción</TableCell>
                <TableCell align="right">Precio</TableCell>
                <TableCell align="right">Stock</TableCell>
                <TableCell align="right">Max. por reserva</TableCell>
                {/* <TableCell align="right">Opciones</TableCell> */}
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                area.extras
                  .map((extra, index) => {
                    if (extra.deleted) return null;
                    return (
                      <TableRow key={index}>
                        <TableCell>{extra.name}</TableCell>
                        <TableCell align="right">{extra.description}</TableCell>
                        <TableCell align="right">{extra.price}</TableCell>
                        <TableCell align="right">{extra.stock}</TableCell>
                        <TableCell align="right">{extra.maxPerReservation}</TableCell>
                        {/* <TableCell align="right">{extra.options?.map((option) => option.name).join(', ')}</TableCell> */}
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              setAddExtraModal(index);
                            }}>
                            <Edit />
                          </IconButton>
                          <IconButton 
                            onClick={() => {
                              const updatedArea = { ...area };
                              updatedArea.extras[index].deleted = true;
                              setArea(updatedArea);
                            }}>
                            <Remove />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );

  return (
    <Box component="form" sx={{}}>
      <LoadingComponent
        isLoading={loadingSave}
        text={areasStore.activo ? 'Actualizando...' : 'Guardando...'}
      />
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabs} onChange={onTabs} aria-label="basic-tabs-area">
              <Tab label="Información" {...a11yProps(0)} />
              <Tab label="Validaciones" {...a11yProps(1)} />
              <Tab label="Mapa del área" {...a11yProps(2)} />
              <Tab label="Extras" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabs} index={0}>
            {infoForm}
          </CustomTabPanel>
          <CustomTabPanel value={tabs} index={1}>
            {validacionesForm}
          </CustomTabPanel>
          <CustomTabPanel value={tabs} index={2}>
            {mapaForm}
          </CustomTabPanel>
          <CustomTabPanel value={tabs} index={3}>
            {extraForm}
          </CustomTabPanel>
        </Grid>
        <Grid item md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
      
      <Modal
        id="addExtraModal"
        title={'Agregar extra'}
        open={addExtraModal !== false}
        maxWidth="sm"
        onClose={() => setAddExtraModal(!addExtraModal)}
      >
        <FormAreaExtra
          extra={area.extras[addExtraModal]}
          onSuccess={(extra) => {
            const updatedArea = { ...area };
            if (typeof addExtraModal === 'number') {
              updatedArea.extras[addExtraModal] = extra;
            } else {
              updatedArea.extras.push(extra);
            }
            setArea(updatedArea);
            setAddExtraModal(false);
          }}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Box>
  );
};

FormAreas.propTypes = {
  getAll: PropTypes.func,
  setOpenModalForm: PropTypes.func,
};

export default FormAreas;
