import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const tiposUsuariosApi = `${baseApi}/entity-user-types`;

export const getAllTiposUsuariosService = async (params) => {
  const res = await axios.get(tiposUsuariosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveTiposUsuariosService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${tiposUsuariosApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${tiposUsuariosApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneTiposUsuariosService = async (id) => {
  const res = await axios.get(`${tiposUsuariosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteTiposUsuariosService = async (id) => {
  const res = await axios.delete(`${tiposUsuariosApi}/${id}`, {
    headers: await token({}),
  });
  return res.data;
};
