import { tryCatchHelper } from '../../helpers';
import {
  deleteTiposAreasService,
  getAllTiposAreasService,
  getOneTiposAreasService,
  saveTiposAreasService,
} from '../../services';
import {
  ACTIVO_TIPOS_AREAS,
  GETALL_TIPOS_AREAS,
  LOADING_GETALL_TIPOS_AREAS,
} from '../../store/Types';

export const getAllTiposAreasAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_AREAS,
      payload: true,
    });
    const res = await getAllTiposAreasService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_AREAS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_AREAS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_AREAS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_AREAS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveTiposAreasAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposAreasService(id, data);
    addNotification(
      res.message ?? id
        ? 'Tipo de área actualizada.'
        : 'Tipo de área registrada.'
    );
    await getAllTiposAreasAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposAreasAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_AREAS,
    payload: data,
  });
};

export const getOneTiposAreasAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposAreasService(id);
    if (res.status === 200) {
      await setActivoTiposAreasAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposAreasAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposAreasService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tipo de área eliminada.');
      await setActivoTiposAreasAction(dispatch, null);
      await getAllTiposAreasAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
