import * as MODULE from '../modules';
import * as ROUTES from './Routes';

const rootRoutesMap = {
  [ROUTES.panelControl.to]: MODULE.PanelControl,
  [ROUTES.users.to]: MODULE.Users,
  [ROUTES.appModules.to]: MODULE.AppModules,
  [ROUTES.entidades.to]: MODULE.Entidades,
  [ROUTES.settings.to]: MODULE.Settings,
  [ROUTES.banks.to]: MODULE.Bancos,
  [ROUTES.notificaciones.to]: MODULE.Notificaciones,
  [ROUTES.servicios.to]: MODULE.Servicios,
  [ROUTES.tiposServicios.to]: MODULE.TiposServicios,
  [ROUTES.panelServicios.to]: MODULE.PanelServicios,
};

const entityRoutesMap = {
  [ROUTES.entidadesInfo.to]: MODULE.EntidadesInfo,
  [ROUTES.dashboard.to]: MODULE.Dashboard,
  [ROUTES.delivery.to]: MODULE.Delivery,
  [ROUTES.usuarios.to]: MODULE.Usuarios,
  [ROUTES.usuariosInfo.to]: MODULE.UsuariosInfo,
  [ROUTES.areas.to]: MODULE.Areas,
  [ROUTES.areasInfo.to]: MODULE.AreasInfo,
  [ROUTES.ajustes.to]: MODULE.Ajustes,
  [ROUTES.modulos.to]: MODULE.Modulos,
  [ROUTES.invitados.to]: MODULE.Invitados,
  [ROUTES.reservas.to]: MODULE.Reservas,
  [ROUTES.panelReservas.to]: MODULE.PanelReservas,
  [ROUTES.panelReservasEspeciales.to]: MODULE.PanelReservasEspeciales,
  [ROUTES.reservasEspeciales.to]: MODULE.ReservasEspeciales,
  [ROUTES.recibos.to]: MODULE.Recibos,
  [ROUTES.documentos.to]: MODULE.Documentos,
  [ROUTES.contactos.to]: MODULE.Contactos,
  [ROUTES.tiposContactos.to]: MODULE.TiposContactos,
  [ROUTES.puertas.to]: MODULE.Puertas,
  [ROUTES.tiposDocumentos.to]: MODULE.TiposDocumentos,
  [ROUTES.tiposInvitacion.to]: MODULE.TiposInvitacion,
  [ROUTES.accesos.to]: MODULE.Accesos,
  [ROUTES.iotsQr.to]: MODULE.Iots,
  [ROUTES.iotTuneles.to]: MODULE.IotTuneles,
  [ROUTES.userDeletionRequest.to]: MODULE.UserDeletionRequest,
  [ROUTES.tiposClases.to]: MODULE.TiposClases,
  [ROUTES.tiposNivelClases.to]: MODULE.TiposNivelClases,
  [ROUTES.clases.to]: MODULE.Clases,
  [ROUTES.panelClases.to]: MODULE.PanelClases,
  [ROUTES.usersDelete.to]: MODULE.UsersDelete,
  [ROUTES.bancos.to]: MODULE.EntidadBancos,
  [ROUTES.eventos.to]: MODULE.Eventos,
  [ROUTES.tiposEventos.to]: MODULE.TiposEventos,
  [ROUTES.tiposImagenClases.to]: MODULE.TiposImagenClases,
  [ROUTES.metodosPago.to]: MODULE.MetodosPago,
  [ROUTES.descuentos.to]: MODULE.Descuentos,
  [ROUTES.referidos.to]: MODULE.Referidos,
  [ROUTES.restaurantes.to]: MODULE.Restaurantes,
  [ROUTES.tiposRestaurante.to]: MODULE.TiposRestaurante,
  [ROUTES.panelRestaurante.to]: MODULE.PanelRestaurante,
  [ROUTES.preciosCustom.to]: MODULE.PreciosCustom,
  [ROUTES.morosidad.to]: MODULE.Morosidad,
  [ROUTES.apikeys.to]: MODULE.ApiKeys,
  [ROUTES.tiposNivelCode.to]: MODULE.TiposNivelCode,
  [ROUTES.tiposAreas.to]: MODULE.TiposAreas,
  [ROUTES.tiposUsuarios.to]: MODULE.TiposUsuarios,
};

export const routesMap = {
  ...rootRoutesMap,
  ...entityRoutesMap,
};
