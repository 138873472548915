import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { Formik } from 'formik';
import useFetch from 'http-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import { baseApi, token } from '../../../services/Apis';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { saveCodigosAction } from '../CodigosAction';

const validationSchema = yup.object().shape({
  code: yup.string().required('El código es requerido'),
  codeType: yup.string().required('El tipo de código es requerido'),
  levelId: yup.string().required('El nivel es requerido'),
  status: yup.string().required('El estado es requerido'),
});

const FormCodigos = ({ setOpenModalForm, params }) => {
  const dispatch = useDispatch();
  const { addNotification } = useNotification();

  const formRef = React.useRef(null);

  const entidadesStore = useSelector((state) => state.entidades);
  const codigosStore = useSelector((state) => state.codigos);

  const [loadingSave, setLoadingSave] = React.useState(false);

  const onSubmit = async (values) => {
    try {
      const data = {
        code: values.code,
        status: values.status,
        codeType: values.codeType,
        levelId: values.levelId,
        entityId: entidadesStore.activo.id,
      };
      setLoadingSave(true);
      let res = await saveCodigosAction(
        { dispatch, addNotification },
        codigosStore.activo?.id,
        data,
        params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.error(error.response.data.message);
        addNotification(error.response.data.message, { error: true });
      }
    } finally {
      setLoadingSave(false);
    }
  };

  const { data: codeLevels, loading } = useFetch(`${baseApi}/code-levels`, {
    headers: token({}),
    query: {
      entityId: entidadesStore.activo.id,
    },
    onResolve: (data) => {
      if (data?.data?.length > 0 && !codigosStore.activo) {
        formRef.current.setFieldValue('levelId', data.data[0].id);
      }
    },
  });

  return (
    <Box component="form" sx={{}}>
      <Formik
        initialValues={{
          code: codigosStore.activo?.code || '',
          codeType: codigosStore.activo?.codeType || entidadesStore.activo.entityType.name === 'office' ? 'staff' : 'owner',
          levelId: codigosStore.activo?.levelId || '',
          status: codigosStore.activo?.status || 'ACTIVE',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        innerRef={formRef}
      >
        {({ values, setFieldValue, handleSubmit, errors, validateField }) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <LoadingComponent
                  isLoading={loadingSave}
                  text={values.activo ? 'Actualizando...' : 'Guardando...'}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    id="code"
                    required
                    label="Código"
                    value={values.code}
                    onChange={(e) => {
                      setFieldValue('code', e.target.value);
                    }}
                    helperText={errors.code || null}
                    error={!!errors.code}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6}>
                <FormControl
                  fullWidth
                  helperText={errors.codeType || null}
                  error={!!errors.codeType}
                >
                  <InputLabel id="codetype-label">Tipo de usuario</InputLabel>
                  <Select
                    labelId="codetype-label"
                    size="small"
                    label="Tipo de usuario"
                    value={values.codeType}
                    disabled={['admin', 'client'].includes(values?.codeType)}
                    onChange={(e) => {
                      setFieldValue('codeType', e.target.value);
                    }}
                    id="codetype-label"
                  >
                    {entidadesStore.activo.entityType.name !== 'office' && (
                      <MenuItem value="owner">Propietario</MenuItem>
                    )}
                    {entidadesStore.activo.entityType.name === 'office' && (
                      <MenuItem value="staff">Staff</MenuItem>
                    )}
                    <MenuItem value="security">Seguridad</MenuItem>
                    {values?.codeType === 'admin' && (
                      <MenuItem value="admin">Administrador</MenuItem>
                    )}
                    {values?.codeType === 'client' && (
                      <MenuItem value="client">Cliente</MenuItem>
                    )}
                    {values?.codeType === 'staff' && (
                      <MenuItem value="staff">Staff</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={6} md={6}>
                <FormControl
                  fullWidth
                  helperText={errors.status || null}
                  error={!!errors.status}
                >
                  <InputLabel id="status-label">Estatus</InputLabel>
                  <Select
                    labelId="status-label"
                    size="small"
                    label="Estatus"
                    value={values.status}
                    onChange={(e) => setFieldValue('status', e.target.value)}
                    id="status-label"
                  >
                    <MenuItem value="ACTIVE">Activo</MenuItem>
                    <MenuItem value="BLOCKED">Bloqueado</MenuItem>
                    <MenuItem value="DEBT">Deudor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6}>
                {loading ? (
                  <Skeleton variant="rectangular" height={42} />
                ) : (
                  <FormControl
                    fullWidth
                    helperText={errors.levelId || null}
                    error={!!errors.levelId}
                  >
                    <InputLabel id="levelId">Nivel</InputLabel>
                    <Select
                      labelId="levelId"
                      size="small"
                      label="Nivel"
                      value={values.levelId}
                      onChange={(e) => setFieldValue('levelId', e.target.value)}
                      id="levelId"
                    >
                      {codeLevels?.data?.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item md={12} align="center" sx={{ m: 2 }}>
                <BotonNuevoComponent
                  click={handleSubmit}
                  disabled={loadingSave}
                  text="GUARDAR"
                  morado
                />
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Box>
  );
};

export default FormCodigos;
