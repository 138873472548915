import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import { SU } from '../../../constants/ConfigConst';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import {
  checkModuleAccess,
  isManagerUserCode,
} from '../../modulos/modules.helpers';
import {
  getAllRestaurantesAction,
  setActivoRestaurantesAction,
} from './RestaurantesAction';
import FormRestaurantes from './components/FormRestaurantes';
import ListadoRestaurantes from './components/ListadoRestaurantes';

const RestaurantesView = () => {
  const dispatch = Redux.useDispatch();

  const restaurantesStore = Redux.useSelector((state) => state.restaurantes);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  const accessName = 'reservation';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const isRestaurant = entidadesStore.activo.entityType.name === 'restaurant';

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {!isManager && !isRestaurant && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoRestaurantesAction(dispatch, null);
              setOpenModalForm(true);
            }}
            azul
            mr={2}
          />
        )}
        {restaurantesStore.all.length > 0 && !isRestaurant ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoRestaurantesAction(dispatch, null);
              await getAllRestaurantesAction(
                dispatch,
                restaurantesStore.params
              );
            }}
            white
            disabled={restaurantesStore.loadingGetAll}
            text={
              restaurantesStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
        <ListadoRestaurantes
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Modal
        id="modalFormRestaurantes"
        title={`${restaurantesStore.activo ? 'Editar' : 'Nuevo'} restaurante`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {restaurantesStore.activo && (
          <Typography variant="h6">
            {restaurantesStore.activo.nombres}{' '}
            {restaurantesStore.activo.apellidos}
          </Typography>
        )}
        <FormRestaurantes
          activo={restaurantesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default RestaurantesView;
