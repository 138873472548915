import axios from 'axios';
import { baseApi, token, isTokenValid } from '../../services/Apis';

export const modulos = `${baseApi}/app-modules`;
export const entityModulos = `${baseApi}/entity-app-modules`;

export const getAllModulosService = async (params) => {
  try {
    const res = await axios.get(modulos, {
      headers: await token({}),
      params,
    });
    return res;
  } catch (e) {
    isTokenValid(e);
    return e.response;
  }
};

export const entityUpdateModulosService = async (id, data) => {
  const res = await axios.put(`${entityModulos}/${id}`, data, {
    headers: await token({}),
  });
  return res;
};

export const saveModulosService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${modulos}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${modulos}`, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const getOneModulosService = async (id) => {
  const res = await axios.get(`${modulos}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const getAllModulosAdminService = async (userCodeId) => {
  try {
    const res = await axios.get(`${modulos}/for-user`, {
      headers: await token({}),
      params: { userCodeId },
    });
    return res;
  } catch (e) {
    isTokenValid(e);
    return e.response;
  }
};


export const updateManyResourcesService = async (data) => {
  const res = await axios.put(`${baseApi}/resources/update-many`, data, {
    headers: await token({}),
  });
  return res;
};


export const updateManyEntityResourcesService = async (data) => {
  const res = await axios.put(`${baseApi}/entity-resources/update-many`, data, {
    headers: await token({}),
  });
  return res;
};
