import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Tab, Tabs } from '@mui/material';
import ListadoModulos from './components/ListadoModulos';
import { getAllModulosAction, setActivoModulosAction } from './ModulosAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { SU } from '../../constants/ConfigConst';
import { MANAGER } from '../../constants/enumsConst';
import { CustomTabPanel } from '../subareas/components/FormSubAreas';
import ListadoModulosGlobal from './components/ListadoModulosGlobal';
import ListadoModulosPanel from './components/ListadoModulosPanel';
import ListadoModulosPanelGlobal from './components/ListadoModulosPanelGlobal';

const ModulosView = ({ isGlobal }) => {
  const dispatch = Redux.useDispatch();
  const modulosStore = Redux.useSelector((state) => state.modulos);

  const loginStore = Redux.useSelector((state) => state.login);
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  const [tabs, setTabs] = React.useState(0);

  // if (!isRoot && !isGlobal) {
  const isManager = loginStore.user.userCodes.some(el => el.userType === MANAGER);
  if (isManager) {
    window.location.href = '/dashboard';
  }
  if (!isRoot && isGlobal) {
    window.location.href = '/modulos';
  }

  const listadoModulosRef = React.useRef(null);
  const listadoModulosPanelRef = React.useRef(null);
  const listadoModulosGlobalRef = React.useRef(null);
  const listadoModulosPanelGlobalRef = React.useRef(null);


  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            listadoModulosRef?.current?.reFetch();
            listadoModulosPanelRef?.current?.reFetch();
            listadoModulosGlobalRef?.current?.reFetch();
            listadoModulosPanelGlobalRef?.current?.reFetch();
          }}
          disabled={modulosStore.loadingGetAll}
          white
          text={modulosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}

        />
      </Grid>
      <Grid item md={12}>
        <Tabs
          style={{ marginBottom: '10px', width: '100%' }}
          value={tabs}
          onChange={(event, newValue) => setTabs(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="App" style={{flex: 1}} />
          <Tab label="Backoffice" style={{flex: 1}} />
        </Tabs>
        <CustomTabPanel value={tabs} index={0}>
          {isGlobal ? <ListadoModulosGlobal ref={listadoModulosGlobalRef} /> : <ListadoModulos ref={listadoModulosRef} />}
        </CustomTabPanel>
        <CustomTabPanel value={tabs} index={1}>
          {isGlobal ? <ListadoModulosPanelGlobal ref={listadoModulosPanelGlobalRef} /> : <ListadoModulosPanel ref={listadoModulosPanelRef} />}
        </CustomTabPanel>
      </Grid>
    </Grid>

  );
};

export default ModulosView;
