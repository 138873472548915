import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import TiposUsuariosView from './TiposUsuariosView';

const TiposUsuariosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposUsuarios} routes={[]}>
      <TiposUsuariosView />
    </ContainerTemplate>
  );
};

export default TiposUsuariosPage;
