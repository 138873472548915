import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { Checkbox, Collapse, FormControlLabel, Grid, Switch } from '@mui/material';
import {
  getAllModulosAction,
  saveModulosAction,
  setActivoModulosAction,
} from '../ModulosAction';
import * as CONST from '../../../constants/FieldsConst';
import { SU } from '../../../constants/ConfigConst';
import { MODULOS_ENUM, getPluralLabelTypeEntity } from '../../../constants/enumsConst';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { PARAMS_MODULOS } from '../../../store/Types';
import LoadingComponent from '../../../components/Loading';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { useNotification } from '../../../helpers/notification';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import useFetch from 'http-react';
import { baseApi, token } from '../../../services/Apis';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Formik } from 'formik';
import { updateManyEntityResourcesService, updateManyResourcesService } from '../ModulosService';
import InfoIcon from '@mui/icons-material/Info';
import { Divider } from '@mui/material';

const ListadoModulosGlobal = React.forwardRef(({ isGlobal, isPanel = false }, ref) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();


  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const modulosStore = Redux.useSelector((state) => state.modulos);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const entity = entidadesStore.activo;

  const su = loginStore.rol === SU;

  const [expanded, setExpanded] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [initialData, setInitialData] = React.useState({
    resources: [],
  });

  const handleExpanded = (id) => {
    if (expanded.includes(id)) {
      setExpanded((old) => old.filter((el) => el !== id));
    } else {
      setExpanded((old) => [...old, id]);
    }
  }


  const { data: resources, loading: loadingResources, reFetch } = useFetch(
    `${baseApi}/resources`,
    {
      id: 'resources-app',
      headers: token({}),
      query: {
        platform: 'APP'
      },
    }
  );

  React.useImperativeHandle(ref, () => ({
    reFetch,
  }));


  React.useEffect(() => {
    if (resources) {

      setInitialData({
        resources: resources,
      });
    }
  }, [resources]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await updateManyResourcesService({
        resources: values.resources.map((er) => ({
          id: er.id,
          active: er.active,
          hidden: er.hidden,
          showInHome: er.showInHome,
          userTypes: er.userTypes.map((eut) => ({
            id: eut.id,
            active: eut.active,
            create: eut.create,
            read: eut.read,
            update: eut.update,
            delete: eut.delete,
          })),
        })),
      });
      addNotification({ message: 'Módulos actualizados correctamente'});
      reFetch();
    } catch (error) {
      addNotification(error.message ?? 'Error al actualizar los módulos', { error: true });
    } finally {
      setLoading(false);
    }
  }


  return (
    <Grid container>

      <LoadingComponent
        isLoading={modulosStore.loadingGetAll || loadingDelete || loading || loadingResources}
        text="Cargando lista..."
      />
      {initialData.resources.length > 0 && (
        <Formik
          initialValues={initialData}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit, errors, validateField, handleChange }) => {
            return (
              <Grid container spacing={2} mb={5}>
                {values.resources?.map((resource) => {
                  const resourceDisabled = !resource.active;
                  const resourceHiddenDisabled = resource.hidden || resourceDisabled;
                  return (
                    <Grid item xs={6} sm={4} md={3} key={resource.id}>
                      <Card>

                        <CardContent>
                          <Grid container justifyContent="space-between" mb={1}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={resource.active}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: 'resources',
                                        value: values.resources.map((r) => r.id === resource.id ? { ...r, active: e.target.checked } : r),
                                      }
                                    });
                                  }}
                                />

                              }
                              label={(
                                <Typography variant="h6" component="h2" gutterBottom color={resourceDisabled ? 'textSecondary' : 'initial'}>
                                  {resource.name}
                                </Typography>
                              )}
                              labelPlacement="start"
                              sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: 0, height: '30px'}}
                            />
                          </Grid>

                          
                          <Divider sx={{ mb: 1 }} />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={resource.hidden}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: 'resources',
                                      value: values.resources.map((r) => r.id === resource.id ? { ...r, hidden: e.target.checked } : r),
                                    }
                                  });
                                }}
                              />

                            }
                            disabled={resourceDisabled}
                            label="Ocultar"
                            labelPlacement="start"
                            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: 0, height: '30px'}}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={resource.showInHome}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: 'resources',
                                      value: values.resources.map((r) => r.id === resource.id ? { ...r, showInHome: e.target.checked } : r),
                                    }
                                  });
                                }}
                              />
                            }
                            disabled={resourceHiddenDisabled}
                            label="Mostrar en Home"
                            labelPlacement="start"

                            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: 0, height: '30px'}}
                          />
          
                          <Box mt={1}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
          
                              onClick={() => handleExpanded(resource.id)}
                            >
                              <Typography variant="body2" my={1} color={resourceHiddenDisabled ? 'textSecondary' : 'initial'}>
                                Tipos de usuarios
                              </Typography>
                              {expanded.includes(resource.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </div>
                            <Collapse in={expanded.includes(resource.id)} timeout="auto" unmountOnExit>
                              {resource.userTypes.map((userType) => {
                                return (
                                  <Box
                                    key={userType.id}
                                    sx={isPanel ? {
                                      marginBottom: '10px',
    
                                      padding: '10px',
                                      border: '1px solid #ddd',
                                      borderRadius: '4px',
                                    } : {}}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={userType.active}
                                          onChange={(e) => {
                                            handleChange({
                                              target: {
                                                name: 'resources',
                                                value: values.resources.map((r) => r.id === resource.id ? { ...r, userTypes: r.userTypes.map((ut) => ut.id === userType.id ? { ...ut, active: e.target.checked } : ut) } : r),
                                              }
                                            });
                                          }}
                                        />
                                      }
                                      disabled={resourceHiddenDisabled}
                                      label={userType.name}
                                      labelPlacement="start"

                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: 0,
                                        height: '30px',
                                      }}
                                    />
                                  </Box>
                                )
                              })}
                            </Collapse>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
          
                  )
                })}

              {JSON.stringify(initialData) !== JSON.stringify(values) && (
                <div style={{ position: 'fixed', bottom: 0, width: 'calc(100% - 300px)', zIndex: 1000, backgroundColor: 'white', padding: '10px', textAlign: 'center', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Guardar cambios
                  </Button>
                </div>
              )}
              </Grid>
            )
          }}
        </Formik>
      )}
    </Grid>
  );
});

export default ListadoModulosGlobal;
