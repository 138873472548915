import { tryCatchHelper } from '../../helpers';
import {
  deleteTiposUsuariosService,
  getAllTiposUsuariosService,
  getOneTiposUsuariosService,
  saveTiposUsuariosService,
} from '../../services';
import {
  ACTIVO_TIPOS_USUARIOS,
  GETALL_TIPOS_USUARIOS,
  LOADING_GETALL_TIPOS_USUARIOS,
} from '../../store/Types';

export const getAllTiposUsuariosAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_USUARIOS,
      payload: true,
    });
    const res = await getAllTiposUsuariosService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_USUARIOS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_USUARIOS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_USUARIOS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_USUARIOS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveTiposUsuariosAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposUsuariosService(id, data);
    addNotification(
      res.message ?? id
        ? 'Tipo de usuario actualizada.'
        : 'Tipo de usuario registrada.'
    );
    await getAllTiposUsuariosAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposUsuariosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_USUARIOS,
    payload: data,
  });
};

export const getOneTiposUsuariosAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposUsuariosService(id);
    if (res.status === 200) {
      await setActivoTiposUsuariosAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposUsuariosAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposUsuariosService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tipo de usuario eliminada.');
      await setActivoTiposUsuariosAction(dispatch, null);
      await getAllTiposUsuariosAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
